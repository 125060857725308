<template>
    <div class="app-header">
        <div class="app-header-content">
            <div class="app-header-title">PlanHub:</div>
            <div class="app-header-dynamic" v-on:click.prevent="top_panel_click" v-html="top_content"></div>
            <div class="app-header-auth" >
                <template v-if="auth">
                    <a href="#" v-on:click.prevent="do_logout" v-text="user_login"></a>
                </template>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .app-header {
        box-sizing: border-box;
        height: 4em;
        max-height: 4em;
        min-height: 4em;
        border-bottom: 1px solid silver;
        user-select:none;
        & *{
            user-select:none;
        }
        .app-header-content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 0 1em;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            .app-header-auth {
                margin-left: auto;
                a {
                    font-weight: bold;
                    color: dimgray;
                    white-space: nowrap;
                    font-size:.9em;
                }
            }
            .app-header-title {
                margin-right: .5em;
                font-weight: bold;
                font-size: 1.25em;
            }
            .app-header-dynamic {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                margin-right: 1em;
                height: 100%;
                .app-header-dynamic-content-runtime {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .app-header-dynamic-title {
                        font-size: 1.1em;
                        font-weight: bold;
                        margin-right: 1em;
                    }
                    .app-header-dynamic-button {
                        box-sizing: border-box;
                        width: 2em;
                        height: 2em;
                        line-height: 0;
                        padding: .25em;
                        border: 1px solid silver;
                        cursor: pointer;
                        transition: all .3s;
                        margin-right: .25em;
                        svg {
                            width: 100%;
                            height: 100%;
                            fill: silver;
                            pointer-events: none;
                        }
                        &:hover {
                            border: 1px solid dimgray;
                            svg {
                                fill: dimgray;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
<script>
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import events from '@/lib/vendor/eve/planhub/lib-events';
    import U from '@/lib/vendor/eve/lib-utils';
    import mustache from '@/lib/vendor/mustache/mustache';
    import template from '!!raw-loader!./templates/appHeader/panel.html';
    import router from '@/router/index';
    export default{
        name: 'app-header',
        data: function () {
            return {
                auth: false,
                user_login: '',
                top_content: ''
            };
        },
        methods: {
            on_ui_state: function (event, cd, key, value) {
                if (key === 'APP-HEADER') {
                    this.top_content = mustache.render(template, U.safeObject(value));
                }
            },
            on_login_state: function () {
                var jam = central_data().jwtMonitor;
                if (U.IntMoreOr(jam.get_payload('id', null), 0, 0)) {
                    this.user_login = ['Выход (',U.NEString(jam.get_payload('display_name', null), null),')'].join('');
                    this.auth = true;
                } else {
                    this.user_login = '';
                    this.auth = false;
                }
            },
            top_panel_click: function (event) {
                if (event.target) {
                    if (event.target.dataset) {
                        var message = U.NEString(event.target.dataset.message, null);
                        if (message) {
                            central_data().lem.run(message);
                        }
                    }
                }
            },
            do_logout: function () {
                central_data().jwtMonitor.clear_token();
                router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
            }
        },
        mounted: function () {
            this.on_login_state();
        },

        created: function () {
            central_data().on(events._UI_STATE_CHANGED, this, this.on_ui_state)
                    .on(events._LOGIN_STATE_CHANGED, this, this.on_login_state);
        }
    }
</script>