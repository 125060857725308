<template>
    <div class="login-component">
        <div class="login-backdrop">
            <div class="login-flex">
                <div class="login-window">
                    <div class="login-title">Вход</div>
                    <div class="login-body">
                        <div class="login-row">
                            <label for="login-input-email">Email</label>
                            <input type="text" v-model="login" id="login-input-email" />
                        </div>
                        <div class="login-row">
                            <label for="login-input-password">Пароль</label>
                            <input type="password" v-model="password" id="login-input-password" />
                        </div>
                    </div>
                    <div class="login-footer">
                        <div class="login-button" v-on:click="try_login">Вход</div>
                    </div>
                    <preloader ref="loader" />
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .login-component {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        .login-backdrop {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background: whitesmoke;
            .login-flex {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .login-window {
                    background: white;
                    border: 1px solid silver;
                    box-shadow: 3px 3px 10px silver;
                    padding: .25em 1em;
                    width: 360px;
                    max-width: 90%;
                    max-height: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    flex-wrap: nowrap;
                    border-radius: 5px;
                    position: relative;
                    .login-title {
                        font-weight: bold;
                        font-size: 1.1em;
                        height: 2em;
                        line-height: 2em;
                        text-align: center;
                        margin-bottom: .5em;
                        border-bottom: 1px solid whitesmoke;
                        max-height: 2em;
                        min-height: 2em;
                    }
                    .login-footer {
                        box-sizing: border-box;
                        height: 3em;
                        max-height: 3em;
                        min-height: 3em;
                        padding-top: .25em;
                        border-top: 1px solid whitesmoke;
                        margin-top: .5em;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1em;
                        padding-left: 1em;
                        padding-right: 1em;
                        .login-button {
                            cursor: pointer;
                            background: silver;
                            line-height: 2.5em;
                            padding: 0 5em;
                            width: 100%;
                            text-align: center;
                            font-weight: bold;
                            user-select: none;
                        }
                    }
                    .login-body {
                        box-sizing: border-box;
                        height: 100%;
                        max-height: 100%;
                        overflow: auto;
                        padding: 1em;
                        .login-row {
                            box-sizing: border-box;
                            margin-bottom: .75em;
                            label {
                                display: block;
                                margin-bottom: .125em;
                            }
                            input[type=text], input[type=password] {
                                box-sizing: border-box;
                                font-family: inherit;
                                font-size: inherit;
                                width: 100%;
                                min-width: 100%;
                                max-width: 100%;
                                margin: 0;
                                padding: 0 .5em;
                                height: 3em;
                                border: 1px solid silver;
                                background: white;
                                transition: border-color .3s;
                                outline: none;
                                box-shadow: none;
                                &:focus {
                                    border-color: black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<script>
    import preloader from '@/lib/vendor/eve/components/local-preloader.vue';
    import Filter from '@/lib/vendor/eve/lib-filter';
    import axios from 'axios';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import {alert} from '@/lib/vendor/eve/lib-alert';
    import router from '@/router/index';
    import U from '@/lib/vendor/eve/lib-utils';

    export default{
        name: 'login-com',
        data: function () {
            return {
                login: '',
                password: ''
            };
        },
        components: {
            preloader: preloader
        },
        methods: {
            showLoader: function () {
                this.$refs.loader.show();
            },
            hideLoader: function () {
                this.$refs.loader.hide();
            },
            try_login: function () {
                try {
                    this.showLoader();
                    var data = Filter().apply_hash_xml(this.$data, require(`!!raw-loader!./validators/login.xml`).default, 'login/export');
                    Filter().throw_bad_value(data);
                    axios.post(central_data().mk_api_url({action: 'login'}, 'auth'), data)
                            .then(this.on_response.bindTo(this))
                            .catch(this.on_error.bindTo(this))
                            .then(this.hideLoader.bindTo(this));
                } catch (e) {
                    this.hideLoader();
                    this.display_error(e.message);
                }
            },
            on_response: function (response) {
                if (!response.canceled) {
                    if (U.isObject(response.data)) {
                        if (response.data.status === 'ok') {
                            return this.on_login_success(response.data);
                        }
                        if (response.data.status === 'error') {
                            return this.on_error(response.data.error_info.message);
                        }
                    }
                    return this.on_error('bad server response');
                }

            },

            on_login_success: function () {
                var query = U.NEString(location.search, '').replace(/^\?/g, '');
                var query_a = query.split('&');
                var h = {};
                for (var i = 0; i < query_a.length; i++) {
                    var pa = U.NEString(query_a[i], '').split('=');
                    if (pa.length === 2) {
                        h[pa[0]] = pa[1];
                    }
                }
                if (Object.prototype.hasOwnProperty.call(h, 'ret')) {
                    var ret = U.NEString(h.ret, null);
                    if (ret) {
                        router.replace(ret);
                        return;
                    }
                }
                router.replace('/');
            },
            on_error: function (re) {
                this.display_error(re);
            },
            display_error: function (message) {
                if (message instanceof Error) {
                    message = message.message;
                }
                alert({
                    text: message,
                    title: "Ошибка",
                    close: false,
                    style: 'crimson',
                    icon: 'preset:stop',
                    timeout: 500000,
                    show: true
                });
            }
        },
        mounted: function () {
            central_data().set_ui_state('APP-HEADER', {
                title: "Вход",
                buttons: []
            });
        }
    }
</script>
