import U from './../lib-utils';
import axios from 'axios';
import {frontend_params} from './lib-frontend-params';
import events from './lib-events';
import {event_manager} from './../lib-event';
import {support_webp} from './../lib-compat';
import {JWTMonitor} from './../lib-jwt-auth-monitor';
import router from '@/router/index';
import {alert} from './../lib-alert';
import project from './project/project';
import Point from './project/layer-point';
var hCD = null;


function central_data() {
    return hCD ? hCD : ((central_data.is(this) ? this.init : central_data.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(central_data).prototype;

var listeners = [];

function run_ready() {
    var c = U.safe_array(listeners);
    listeners = null;
    this.ready.apply(this, c);
}
P.lem = null;

P.frontend_params = null;
P.ui_state = null;// состояние ui - набор ключей
P.sWebp = null;
P.jwtMonitor = null;
P.tree = null;
P.selected_tool = null;
P.image_canvas = null;

P.cache = null;

P.selection = null;



P.init = function () {
    hCD = this;
    this.ui_state = {};
    this.lem = event_manager();
    this.cache = {};
    support_webp(this._wsc.bindTo(this));
    this.lem.on(events._SELECTION_DID_UPDATE, this, this.monitor_project_defaults);
    return this;
};

P.monitor_project_defaults = function () {
    if (this.current_layer.points.length === 1) {
        this.project.header.size = this.current_layer.points[0].size;
        this.project.header.fill = this.current_layer.points[0].fill;
        this.project.header.stroke = this.current_layer.points[0].stroke;
        this.lem.run(events._PROJECT_PROP_CHANGED, this);
    }
};

P.set_selected_tool = function (tool) {
    var ost = this.selected_tool;
    this.selected_tool = tool;
    if (U.isObject(ost)) {
        ost.permanent = false;
    }
    if (U.isObject(ost) && U.isObject(tool)) {
        if (ost.id === tool.id) {
            return this;
        }
    }
    this.lem.run(events._TOOL_CHANGED, this, this.selected_tool, ost);
    this.set_selected_item(null);
    return this;
};

P._wsc = function (r) {
    this.sWebp = U.any_bool(r, false);
    this.load_frontend_params();
};

P.mk_api_url = function (objGet, controller) {
    controller = U.NEString(controller, 'planhub');
    objGet = U.safe_object(objGet);
    var url = ['https://', this.frontend_params.server, '/app/', controller, '/API'].join('');
    var args = [];
    for (var k in objGet) {
        if (Object.prototype.hasOwnProperty.call(objGet, k) && !U.is_callable(objGet[k]) && !U.is_object(objGet[k])) {
            args.push([window.encodeURIComponent(k), window.encodeURIComponent(objGet[k])].join('='));
        }
    }
    if (args.length) {
        return [url, args.join('&')].join('?');
    }
    return url;
};

//<editor-fold defaultstate="collapsed" desc="initial loading">
P.load_frontend_params = function () {
    // сначала загрузим настроечный блок
    window.axios = axios;
    axios.get('/frontend.json')
            .then(this.on_frontend_params.bindTo(this))
            .catch(this.on_load_fail.bindTo(this));
};

P.on_frontend_params = function (response) {
    if (U.is_object(response.data)) {
        var sp = U.safeObject(response.data);
        if (sp.status === 'ok') {
            try {
                this.frontend_params = frontend_params(sp);
                this.jwtMonitor = JWTMonitor();
                this.jwtMonitor.on(this, this.on_user_changed);
                return this.load_meta();
            } catch (e) {
                return this.on_load_fail(e.message);
            }
        } else if (sp.status === 'error') {
            return this.on_load_fail(sp.error_info.message);
        }
    }
    return this.on_load_fail('-lib-map:centralData:cant load frontend params');
};

P.on_user_changed = function () {
    this.lem.run(events._LOGIN_STATE_CHANGED, this);
};



P.load_meta = function () {
    axios.get(this.mk_api_url({action: 'categories'}, 'info'))
            .then(this.on_meta_response.bindTo(this))
            .catch(this.on_load_fail.bindTo(this));
};

P.on_meta_response = function (response) {
    if (U.is_object(response.data)) {
        if (response.data.status === 'ok') {
            return this.on_meta_loaded(response.data);
        } else if (response.data.status === 'error') {
            return this.on_load_fail(response.data.erro_info.message);
        }
    }
    return this.on_load_fail('invalid server response');
};
P.on_meta_loaded = function (rso) {
    var tree_raw = U.safe_array(rso.tree);
    this.tree = tree_raw;


    run_ready.call(this);

    //this.enable_url_navigation();
};

P.enable_url_navigation = function () {
    window.onpopstate = this._onpopstate.bind_to(this);
    this._onpopstate();
};
P._onpopstate = function () {
    var m = /\/project\/(\d{1,})/.exec(window.location.pathname);
    if (m) {
        var id = U.IntMoreOr(m[1], 0, null);
        if (id) {
            if (id !== this.project_id) {
                this.project_id = id;
                this.lem.run(events._PROJECT_CHANGED);
            }
        }
    } else {
        this.project_id = null;
    }

};

P.on_load_fail = function () {
    console.log(arguments);
    throw new Error('error while loading settings and metadata');
};
//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="lem proxy">
P.on = function () {
    return this.lem.on.apply(this.lem, Array.prototype.slice.call(arguments));
};
P.off = function () {
    return this.lem.off.apply(this.lem, Array.prototype.slice.call(arguments));
};
P.once = function () {
    return this.lem.once.apply(this.lem, Array.prototype.slice.call(arguments));
};
//</editor-fold>


//<editor-fold defaultstate="collapsed" desc="ui state">
/**
 * 
 * @param {string} state_key
 * @param {any} state_value
 * @returns {central_data}
 */
P.set_ui_state = function (state_key, state_value) {
    var pv = this.get_ui_state(state_key);
    this.ui_state[state_key] = state_value;
    if (pv !== state_value) {
        this.lem.run(events._UI_STATE_CHANGED, this, state_key, state_value);
    }
};
/**
 * 
 * @param {string} state_key
 * @returns {any}
 */
P.get_ui_state = function (state_key) {
    return Object.prototype.hasOwnProperty.call(this.ui_state, state_key) ? this.ui_state[state_key] : void(0);
};
//</editor-fold>




//<editor-fold defaultstate="collapsed" desc="project ops">
P.load_project = function (project_id) {
    if (this.project && this.project.id === U.IntMoreOr(project_id, 0, null)) {
        if (this.project_loaded) {
            this.lem.run(events._PROJECT_LOAD_COMPLETE);
        } else {
            this.lem.run(events._PROJECT_CHANGED);

        }
        return this;
    }
    this.project_loaded = false;
    this.project = null;
    axios.get(this.mk_api_url({action: 'get_project', id: project_id}))
            .then(this.project_load_response.bindTo(this))
            .catch(this.project_load_fail.bindTo(this));
};

P.project_load_response = function (r) {
    if (r) {
        if (U.isObject(r.data)) {
            if (r.data.status === 'ok') {
                return this.project_load_success(r.data);
            }
            if (r.data.status === 'error') {
                return this.project_load_fail(r.data.error_info.message);
            }
        }
        this.project_load_fail('bad server response');
    }
};

P.project_load_fail = function (msg) {
    if (msg instanceof Error) {
        msg = msg.message;
    }
    msg = U.NEString(msg, 'unknown error');
    alert({
        text: msg,
        title: 'Ошибка',
        icon: 'preset:stop',
        timeout: 5000,
        close: false,
        show: true,
        style: 'crimson'
    });
    this.project = null;
    this.project_id = null;
    this.lem.run(events._PROJECT_LOAD_FAIL, this);
    router.replace('/');
};
P.project_load_success = function (data) {
    this.project = project(data.project);
    if (!this.project.isValid()) {
        return this.project_load_fail('error in project');
    }
    this.project_image = new Image();
    this.project_image.crossOrigin = 'Anonymous';
    this.project_image.onload = this.on_image_loaded.bindTo(this);
    this.project_image.onerror = this.project_load_fail.bindTo(this);
    this.project_image.src = this.project.header.image_url + '.S!default.jpg?_=' + this.project.header.harsh;
};

P.on_image_loaded = function () {
    this.image_canvas = document.createElement('canvas');
    this.image_canvas.width = this.project_image.width;
    this.image_canvas.height = this.project_image.height;
    this.image_canvas.getContext('2d').drawImage(this.project_image, 0, 0);
    this.current_layer = this.project.layers[0];
    this.lem.run(events._PROJECT_LOAD_COMPLETE, this);

};

P.place_tool = function (event) {
    //  console.log(event);
    this.current_layer.points.push(Point({
        id: null,
        name: this.selected_tool.name + ' #' + this.current_layer.points.length + 1,
        layer_id: this.current_layer.id,
        primitive_id: this.selected_tool.id,
        primitive_name: this.selected_tool.name,
        x: event.calculated.image.pcu.x,
        y: event.calculated.image.pcu.y,
        size: U.FloatMoreOr(this.project.header.size, 0, 10),
        fill: this.project.header.fill,
        stroke: this.project.header.stroke,
        rotate: 0
    }));
    this.lem.run(events._NEW_POINT, this);
    if (!this.selected_tool.permanent) {
        this.set_selected_tool(null);
    }
};


P.set_selected_item = function (sel) {
    var osi = this.selection;
    this.selection = Point.is(sel) ? sel : null;
    if ((Point.is(osi) && this.selection && this.selection.uid !== osi.uid) || (!Point.is(osi) && this.selection) || (!this.selection && Point.is(osi))) {
        this.lem.run(events._SELECTION_CHANGED, this, this.selection, osi);
    }
    return this;
};


//<editor-fold defaultstate="collapsed" desc="selection updates">
P.set_selection_key = function (key, x, y) {
    if (this.selection) {
        var method = ['set_selection_key_', key].join('');
        if (U.isCallable(this[method])) {
            this[method](x, y);

        } else {
            this.selection[key] = U.FloatOr(x, 0);
            this.lem.run(events._SELECTION_DID_UPDATE, this.selection);
            this.lem.run(events._SELECTION_CHANGED, this.selection);
        }
    }
};
P.set_selection_key_size = function (x) {
    this.selection.size = U.FloatMoreOr(x, 0, this.selection.size);
    this.lem.run(events._SELECTION_DID_UPDATE, this.selection);
    this.lem.run(events._SELECTION_CHANGED, this.selection);

};
P.set_selection_key_rotate = function (x) {
    this.selection.rotate = U.FloatOr(x, 0, this.selection.rotate);
    this.lem.run(events._SELECTION_DID_ROTATE, this.selection);
    this.lem.run(events._SELECTION_CHANGED, this.selection);
};
P.create_color = function (rgb, a) {
    var m = /^#{0,1}([0-9a-f]{6})$/i.exec(rgb);
    if (m) {
        a = U.IntMoreOr(a, -1, 0);
        a = Math.min(a, 255);
        a = Math.max(0, a);
        a = U.pad_left(a.toString(16), 2, '0');
        return ['#', m[1], a].join('');
    }
    return '#00000000';
};
P.set_selection_key_fill = function (x, y) {
    this.selection.fill = this.create_color(x, y);
    this.lem.run(events._SELECTION_DID_UPDATE, this.selection);
};
P.set_selection_key_stroke = function (x, y) {
    this.selection.stroke = this.create_color(x, y);
    this.lem.run(events._SELECTION_DID_UPDATE, this.selection);
};
//</editor-fold>

P.set_project_key = function (key, x, y) {
    if (this.project) {
        var method = ['set_project_key_', key].join('');
        if (U.isCallable(this[method])) {
            this[method](x, y);
        } else {
            this.project.header[key] = U.FloatOr(x, 0);
        }
        this.lem.run(events._PROJECT_PROP_CHANGED, this);
    }
};

P.set_project_key_size = function (x) {
    this.project.header.size = U.FloatMoreOr(x, 0, this.project.header.size);
};

P.set_project_key_fill = function (x, y) {
    this.project.header.fill = this.create_color(x, y);
};

P.set_project_key_stroke = function (x, y) {
    this.project.header.stroke = this.create_color(x, y);
};
P.set_project_key_overtone = function (x, y) {
    this.project.header.overtone = this.create_color(x, y);
};

P.remove_selected = function () {
    if (this.selection) {
        var c = this.selection.uid;
        this.set_selected_item(null);
        this.current_layer.remove_point_by_uid(c);
        this.lem.run(events._POINT_REMOVED, c);
    }
};







//</editor-fold>


P.save_project = function () {
    var m = JSON.parse(JSON.stringify(this.project.header));
    m.points = this.project.layers[0].points;
    var data = JSON.stringify(m);
    axios.post(this.mk_api_url({action: 'save_project'}), {data: data})
            .then(this.on_project_post_response.bindTo(this))
            .catch(this.on_project_post_fail.bindTo(this));
    return this;
};

P.on_project_post_response = function (re) {
    if (re) {
        if (U.isObject(re.data)) {
            if (re.data.status === 'ok') {
                return this.project_load_success(re.data);
            }
            if (re.data.status === 'error') {
                return this.on_project_post_fail(re.data.error_info.message);
            }
        }
        return this.on_project_post_fail('bad server response');
    }
};

P.on_project_post_fail = function (ms) {
    if (ms instanceof Error) {
        ms = ms.message;
    }
    ms = U.NEString(ms, 'unknown error');
    alert({
        text: ms,
        title: 'Ошибка',
        icon: 'preset:stop',
        timeout: 5000,
        close: false,
        show: true,
        style: 'crimson'
    });
};


P.create_drawing = function (callback) {
    var canvas = document.createElement('canvas');
    canvas.width = this.image_canvas.width;
    canvas.height = this.image_canvas.height;
    var context = canvas.getContext('2d');
    context.drawImage(this.image_canvas, 0, 0);
    var svg = {callback: function (image) {
            context.drawImage(image, 0, 0);
            if (U.isCallable(callback)) {
                callback(canvas.toDataURL('image/jpeg', .95));
            }
        }};
    this.lem.run(events._GET_SVG_LAYER, svg);
};


//<editor-fold defaultstate="collapsed" desc="ready">
P.ready = function () {
    var args = Array.prototype.slice.call(arguments);
    if (listeners === null) {
        for (var iCall = 0; iCall < args.length; iCall++) {
            if (U.is_callable(args[iCall])) {
                args[iCall](this);
            }
        }
    } else {
        for (var iPush = 0; iPush < args.length; iPush++) {
            listeners.push(args[iPush]);
        }
    }
};
//</editor-fold>
export {central_data as central_data, central_data as centralData};