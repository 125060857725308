<template>
    <div class="float-panel-wrapper" v-bind:style="{top:y+dimp,left:x+dimp,width:w+dims,height:h+dims}" ref='handle'>
        <div class="float-panel-content">
            <div class="float-panel-title" v-on:mousedown.left="dragTest"><div class="float-panel-title-text" v-text="title"></div></div>
            <div class="float-panel-body"><slot></slot></div>
            <div class="float-panel-resize-handler" v-on:mousedown.left="sizeTest"></div>
        </div>
    </div>
</template>
<style lang="scss">
    .float-panel-wrapper {
        box-sizing: border-box;
        position: absolute;
        background: white;
        box-shadow: 1px 1px 5px silver;
        .float-panel-content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .float-panel-title {
                height: 24px;
                background: dimgray;
                max-height: 24px;
                min-height: 24px;
                line-height: 24px;
                box-sizing: border-box;
                padding: 0 .5em;
                color: white;

                cursor: pointer;
                user-select: none;


                .float-panel-title-text {
                    font-size: .9em;
                    pointer-events: none;
                }
            }

            .float-panel-body {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                padding: 0;
                overflow: hidden;
                border: 1px solid silver;
            }

            .float-panel-resize-handler {
                box-sizing: border-box;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 1em;
                height: 1em;
                user-select:none;
                cursor: nwse-resize;
                background: linear-gradient(to bottom right,transparent 49%,transparent 50%,dimgray 55%,dimgray 56%,transparent 57%,transparent 64%,transparent 65%,dimgray 70%,dimgray 71%,transparent 72%,transparent 80%,transparent 81%,dimgray 82%,dimgray 84%,dimgray 85%,transparent 86%,transparent 87%,transparent 94%,transparent 95%,dimgray 96%);
            }
        }
    }

</style>
<script>
    import U from '@/lib/vendor/eve/lib-utils';
    export default{
        name: 'float-panel',
        props: {
            id: {
                type: String,
                'default': null
            },
            title: {
                type: String,
                'default': 'float-panel'
            },
            dx: {
                type: Number,
                'default': 0
            },
            dy: {
                type: Number,
                'default': 0
            },
            dw: {
                type: Number,
                'default': 30
            },
            dh: {
                type: Number,
                'default': 50
            }
        },
        data: function () {
            return {
                x: 0,
                y: 0,
                w: 30,
                h: 50,
                dimp: '%',
                dims: '%'
            };
        },
        methods: {
            //<editor-fold defaultstate="collapsed" desc="move">
            abort_drag_test: function () {
                document.removeEventListener('mousemove', this.while_drag_test.bindTo(this));
                document.removeEventListener('mouseup', this.abort_drag_test.bindTo(this));
            },
            while_drag_test: function (event) {
                var dx = event.pageX - this._start.x;
                var dy = event.pageY - this._start.y;
                var dc = Math.sqrt(dx * dx + dy * dy);
                if (dc > 5) {
                    this.abort_drag_test();
                    document.addEventListener('mousemove', this.while_drag.bindTo(this));
                    document.addEventListener('mouseup', this.end_drag.bindTo(this));
                    this._started = {
                        x: this.$refs.handle.offsetLeft,
                        y: this.$refs.handle.offsetTop
                    };
                    this.while_drag(event);
                }
            },
            while_drag: function (event) {
                var dx = event.pageX - this._start.x;
                var dy = event.pageY - this._start.y;
                this.$data.x = this._started.x + dx;
                this.$data.y = this._started.y + dy;
                if (this.$data.dimp !== 'px') {
                    this.$data.dimp = 'px';
                }
            },
            end_drag: function (event) {
                document.removeEventListener('mousemove', this.while_drag.bindTo(this));
                document.removeEventListener('mouseup', this.end_drag.bindTo(this));
                this.while_drag(event);
                var p = this.$refs.handle.parentElement;
                var fW = p.offsetWidth;
                var fH = p.offsetHeight;
                var dx = event.pageX - this._start.x;
                var dy = event.pageY - this._start.y;
                var cx = this._started.x + dx;
                var cy = this._started.y + dy;
                var cxp = U.floatOr((cx / (fW / 100)).toFixed(6), 0);
                var cyp = U.floatOr((cy / (fH / 100)).toFixed(6), 0);
                this.$data.x = cxp;
                this.$data.y = cyp;
                this.$data.dimp = '%';
                this.save_state({x: cxp, y: cyp});
            },
            dragTest: function (event) {
                this._start = {
                    x: event.pageX,
                    y: event.pageY
                };
                document.addEventListener('mousemove', this.while_drag_test.bindTo(this));
                document.addEventListener('mouseup', this.abort_drag_test.bindTo(this));
            },
            //</editor-fold>  
            //<editor-fold defaultstate="collapsed" desc="sizing">
            sizeTest: function (event) {
                this._start = {
                    x: event.pageX,
                    y: event.pageY
                };
                document.addEventListener('mousemove', this.while_size_test.bindTo(this));
                document.addEventListener('mouseup', this.abort_size_test.bindTo(this));
            },
            abort_size_test: function () {
                document.removeEventListener('mousemove', this.while_size_test.bindTo(this));
                document.removeEventListener('mouseup', this.abort_size_test.bindTo(this));
            },
            while_size_test: function (event) {
                var dx = event.pageX - this._start.x;
                var dy = event.pageY - this._start.y;
                var dc = Math.sqrt(dx * dx + dy * dy);
                if (dc > 3) {
                    this.abort_size_test();
                    document.addEventListener('mousemove', this.while_size.bindTo(this));
                    document.addEventListener('mouseup', this.end_size.bindTo(this));
                    this._started = {
                        w: this.$refs.handle.offsetWidth,
                        h: this.$refs.handle.offsetHeight
                    };
                    this.while_size(event);
                }
            },
            while_size: function (event) {
                var dx = event.pageX - this._start.x;
                var dy = event.pageY - this._start.y;
                this.$data.w = Math.max(this._started.w + dx, 40);
                this.$data.h = Math.max(this._started.h + dy, 50);
                if (this.$data.dims !== 'px') {
                    this.$data.dims = 'px';
                }
            },
            end_size: function (event) {
                document.removeEventListener('mousemove', this.while_size.bindTo(this));
                document.removeEventListener('mouseup', this.end_size.bindTo(this));
                this.while_size(event);
                var p = this.$refs.handle.parentElement;
                var fW = p.offsetWidth;
                var fH = p.offsetHeight;
                var dx = event.pageX - this._start.x;
                var dy = event.pageY - this._start.y;
                var cx = Math.max(this._started.w + dx, 40);
                var cy = Math.max(this._started.h + dy, 50);
                var cxp = U.floatOr((cx / (fW / 100)).toFixed(6), 0);
                var cyp = U.floatOr((cy / (fH / 100)).toFixed(6), 0);
                this.$data.w = cxp;
                this.$data.h = cyp;
                this.$data.dims = '%';
                this.save_state({w: cxp, h: cyp});
            },
            //</editor-fold>


            save_state: function (nO) {
                var o = null;
                try {
                    o = JSON.parse(window.localStorage.getItem('float-panel-' + this.$props.id));
                } catch (e) {
                    o = null;
                }
                o = U.safeObject(o);
                U.deep_clone(nO, o);
                window.localStorage.setItem('float-panel-' + this.$props.id, JSON.stringify(o));
            }

        },
        created: function () {
            var o = null;
            try {
                o = JSON.parse(window.localStorage.getItem('float-panel-' + this.$props.id));
            } catch (e) {
                o = null;
            }
            o = U.safeObject(o);
            this.$data.x = U.floatOr(o.x, U.floatOr(this.$props.dx, 0));
            this.$data.y = U.floatOr(o.y, U.floatOr(this.$props.dy, 0));
            this.$data.w = U.floatOr(o.w, U.floatOr(this.$props.dw, 25));
            this.$data.h = U.floatOr(o.h, U.floatOr(this.$props.dh, 50));
        }

    }
</script>
