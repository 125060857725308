<template>
    <div class="app-wrapper">
        <app-header />
        <div class="application-routable-content"><router-view></router-view></div>
    </div>  
</template>

<style lang="scss">
    .app-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: stretch;
        .application-routable-content {
            box-sizing: border-box;
            height: 100%;
            overflow: hidden;
        }
    }
</style>
<script>
    import header from '@/components/appHeader.vue';

    require('@/assets/css/app.scss');
    export default{
        name: 'app',
        data: function () {
            return {};
        },
        components: {
            appHeader: header
        }
    };

</script>