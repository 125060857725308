/* eslint-disable */
import U from './../lib-utils';
import abstractLayer from './abstractLayer';
import layerLayout from './layerLayout';
import Mustache from './../../mustache/mustache';
//import resolverManager from './imageResolver/resolverManager';
import tpl_main from '!!raw-loader!./templates/svg-layer/template.svg';
import tpl_items from '!!raw-loader!./templates/svg-layer/items.svg';
import primitiveCache from './project/primitiveCache';
import events from './lib-events';

/*
 * рендеринг:
 * размеры svg совпадают с размерами вьюпорта
 * в нем расположен еще один свг, размеры и позиция которого совпадают с текущим отображением бэка
 * в позиционном svg расположены точки как отдельные svg элементы с размером и стилями
 * и они могут вываливаться за пределы основного svg - во превых их размеры и позиция зависят от него
 */
/**
 * 
 * @param {type} layout
 * @returns {layer.F|layer.init}
 */
function layer(layout) {
    return (layer.is(this) ? this.init : layer.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = layer.leu_extend(abstractLayer).prototype;


P.handle = null;
P.wrapper = null;
P.drawer = null;
P.transformer = null;

P.on_init = function (layout) {
    var d = document.createElement('div');
    d.innerHTML = Mustache.render(tpl_main, this);
    this.handle = d.children[0];
    this.wrapper = this.handle.children[0];
    this.transformer = this.wrapper.children[0];
    this.drawer = this.transformer.children[0];
    this.layout.centralData.lem.on(events._PRIMITIVE_LOADED, this, this.plan_update);
    this.layout.centralData.lem.on(events._NEW_POINT, this, this.on_image_ready);
    this.layout.centralData.lem.on(events._SELECTION_DID_UPDATE, this, this.on_selection_repaint);
    this.layout.centralData.lem.on(events._SELECTION_DID_ROTATE, this, this.on_selection_repaint);
    this.layout.centralData.lem.on(events._PROJECT_CHANGED, this, this.update_background);
    this.layout.centralData.lem.on(events._PROJECT_LOAD_COMPLETE, this, this.update_background);
    this.layout.centralData.lem.on(events._PROJECT_PROP_CHANGED, this, this.update_background);
    this.layout.centralData.lem.on(events._POINT_REMOVED, this, this.on_point_removed);
    this.layout.centralData.lem.on(events._GET_SVG_LAYER, this, this.get_svg_layer);

    return this;
};

function utf8_to_b64( str ) {
   return window.btoa(unescape(encodeURIComponent( str )));
}

P.get_svg_layer = function (evt, ro) {
    if (!U.anyBool(ro.finished, false)) {
        ro.finished = true;
        var root_node = this.drawer.cloneNode(true);
        var defs = document.createElement('defs');
        root_node.appendChild(defs);
        primitiveCache().clone_defs_to(defs);
        var svgXml = new XMLSerializer().serializeToString(root_node);
        var svgBase64 = "data:image/svg+xml;base64," + utf8_to_b64(svgXml);
        var image = new Image();

        image.onload = function () {
            if (U.isCallable(ro.callback)) {
                ro.callback(this);
            }
        };
        image.crossOrigin = 'Anonymous';
        image.src = svgBase64;
    }
};



P.on_point_removed = function (evt, uid) {
    var id = ['svg-layer-point-uid-', uid].join('');
    var node = document.getElementById(id);
    if (node) {
        this.drawer.removeChild(node);
    }
};


P.update_background = function () {
    var bg = document.getElementById('svg-layer-overtone');
    if (bg) {
        bg.setAttribute('fill', this._get_overtone());
    }

};

P.on_selection_repaint = function (evt, point) {
    var id = ['svg-layer-point-uid-', point.uid].join('');
    var node = document.getElementById(id);
    if (node) {
        node.setAttribute('width', [this._get_size_x(point), '%'].join(''));
        node.setAttribute('height', [this._get_size_y(point), '%'].join(''));
        node.setAttribute('x', [this._get_x(point), '%'].join(''));
        node.setAttribute('y', [this._get_y(point), '%'].join(''));
        node.setAttribute('fill', this._get_fill(point));
        node.setAttribute('stroke', this._get_stroke(point));
        node.style.transform = ['rotate(', this._get_rotation(point), 'deg)'].join('');
        node.style.transformOrigin = [this._get_center_x(point), '% ', this._get_center_y(point), '%'].join('');
    }
};

P.plan_update = function () {
    if (this._to) {
        window.clearTimeout(this._to);
        this._to = null;
    }
    this._to = window.setTimeout(this._update.bindTo(this), 300);
};

P._update = function () {
    if (this._to) {
        window.clearTimeout(this._to);
        this._to = null;
    }
    window.requestAnimationFrame(this.on_image_ready.bindTo(this));
};

P.init_binders = function () {
    abstractLayer.prototype.init_binders.apply(this, Array.prototype.slice.call(arguments));
    this.get_x = this._get_x.bindToWP(this);
    this.get_y = this._get_y.bindToWP(this);
    this.get_center_x = this._get_center_x.bindToWP(this);
    this.get_center_y = this._get_center_y.bindToWP(this);
    this.get_size_x = this._get_size_x.bindToWP(this);
    this.get_size_y = this._get_size_y.bindToWP(this);
    this.get_fill = this._get_fill.bindToWP(this);
    this.get_stroke = this._get_stroke.bindToWP(this);
    this.is_tool_loaded = this._is_tool_loaded.bindToWP(this);
    this.get_rotation = this._get_rotation.bindToWP(this);
    this.get_overtone = this._get_overtone.bindTo(this);
};

P._get_overtone = function () {
    return U.NEString(this.layout.centralData.project.header.overtone, 'none');
};

P._get_rotation = function (n) {
    return U.FloatOr(n.rotate, 0).toFixed(5);
};
P._get_x = function (n) {
    return(n.x - n.size / 2).toFixed(5);
};
P._get_y = function (n) {
    return(n.y - n.size * (this.get_image_width() / this.get_image_height()) / 2).toFixed(5);
};

P._get_center_x = function (n) {
    return n.x.toFixed(5);
};
P._get_center_y = function (n) {
    return n.y.toFixed(5);
};

P._get_size_x = function (n) {
    return U.FloatMoreOr(n.size, 0, 10);
};
P._get_size_y = function (n) {
    var size = U.FloatMoreOr(n.size, 0, 10);
    return (size * (this.get_image_width() / this.get_image_height())).toFixed(5);
};

P._get_fill = function (n) {
    return U.NEString(n.fill, 'none');
};
P._get_stroke = function (n) {
    return U.NEString(n.stroke, '#000000');
};


P._is_tool_loaded = function (x) {
    var r = primitiveCache().has(x.primitive_id);
    if (!r) {
        primitiveCache().load(x.primitive_id);
    }
    return r;
};


P.clear = function () {
    //this.handle.innerHTML = '';
    //this.style.innerHTML = '';
    //this.runtime_style.innerHTML = '';
    this.items = [];
    return this;
};

P.resize = function () {
    if (this.wrapper) {
        this.wrapper.setAttribute('width', this.get_layout_width());
        this.wrapper.setAttribute('height', this.get_layout_height());
        this.wrapper.setAttribute('viewBox', [0, 0, this.get_layout_width(), this.get_layout_height()].join(' '));
    }
    if (this.drawer) {
        //   console.log(this.layout);
    }

    return this;
};
P.on_data_ready = function () {

};

P.on_image_ready = function () {
    this.drawer.setAttribute('width', this.get_image_width());
    this.drawer.setAttribute('height', this.get_image_height());
    this.drawer.setAttribute('viewBox', ['0', '0', this.get_image_width(), this.get_image_height()].join(' '));
    this.items = U.safeArray(this.layout.centralData.current_layer.points);
    this.drawer.innerHTML = Mustache.render(tpl_items, this);
};


P.draw = function () {
    var x = (this.get_pixel_image_offset_x()).toFixed(10);
    var y = (this.get_pixel_image_offset_y()).toFixed(10);
    var sty = ['translate3d(', x, 'px,', y, 'px,0) scale(', this.layout.scale.toFixed(10), ')'].join('');
    this.transformer.style.transform = sty;
};


P.on_click_tap = function (event) {
    var sas = U.safeArray(this.layout.centralData.current_layer.points);
    var project = this.layout.centralData.project;
    var px = event.calculated.image.pcu.x;
    var py = event.calculated.image.pcu.y;
    var aspect = this.get_image_width() / this.get_image_height();
    for (var i = sas.length - 1; i >= 0; i--) {
        var item = sas[i];
        var hWidth = (U.FloatMoreOr(item.size, 0, project.size) / 2);
        var hHeight = (U.FloatMoreOr(item.size, 0, project.size) / 2) * aspect;
        if (px >= item.x - hWidth && px <= item.x + hWidth && py >= item.y - hHeight && py <= item.y + hHeight) {
            this.layout.centralData.set_selected_item(item);
            return true;
        }
    }
    this.layout.centralData.set_selected_item(null);
    return false;
};

export default layer;