<template>
    <div class="plan-display-wrapper">
        <div class="plan-display-content" ref="layout">                        
        </div>
    </div>
</template>
<style lang="scss">
    .plan-display-wrapper {
        box-sizing: border-box;
        height: 100%;
        width: 100%;

        .plan-display-content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

    }
</style>
<script>
    import layerLayout from '@/lib/vendor/eve/planhub/layerLayout';
    export default{
        name: 'plan-display',
        data: function () {
            return {

            };
        },
        methods: {
            draw: function () {

            }
        },
        mounted: function () {
            if (!this.layer_layout) {
                this.layer_layout = layerLayout(this.$refs.layout);
            }
        }
    }
</script>
