import U from './../lib-utils';
import abstractLayer from './abstractLayer';
//import layerLayout from './layerLayout';


function background_layer() {
    return (background_layer.is(this) ? this.init : background_layer.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = background_layer.leu_extend(abstractLayer).prototype;


P.canvas = null;
P.context = null;
P.canvas_antialiasing_supported = null;
P.svg_filters_supported = null;
P.step_down_cache = null;
//P.icanvas = null;
//P.icontext = null;

P.on_init = function () {
    this.handle = document.createElement('div');
    this.handle.classList.add('lib-map-layer');
    this.handle.classList.add('lib-map-layer-background-v-cnv');
    this.canvas = document.createElement('canvas');
    this.handle.appendChild(this.canvas);
    this.context = this.canvas.getContext('2d');
//    this.icanvas = document.createElement('canvas');
//    this.icontext = this.icanvas.getContext('2d');
    this.canvas_antialiasing_supported = U.is_defined(this.context.imageSmoothingQuality);
    this.svg_filters_supported = U.is_defined(this.context.filter);
    if (!this.super_antialiasing_supported && this.svg_filters_supported) {
        this.step_down_cache = {};
    }
    try {
        this.context.imageSmoothingEnabled = true;
        this.context.imageSmoothingQuality = 'high';
    } catch (e) {
        //do nothing
    }

    return this;
};


/**
 * Вызывается менеджером 
 * @returns {background_layer}
 */
P.clear = function () {
    this.context.clearRect(0, 0, this.get_layout_width(), this.get_layout_height());
    this.step_down_cache = null === this.step_down_cache ? null : {};
    return this;
};

/**
 * Вызывается менеджером после ресайза
 * @returns {background_layer}
 */
P.resize = function () {
    this.canvas.width = this.get_layout_width();
    this.canvas.height = this.get_layout_height();
    return this;
};

/**
 * 
 * @returns {background_layer}
 */
P.on_data_ready = function () {

};


P.on_image_ready = function () {
//    this.icanvas.width = this.get_image_width();
//    this.icanvas.height = this.get_image_height();
//    this.icontext.imageSmoothingEnabled = true;
//    this.icontext.msimageSmoothingEnabled = true;
//    this.icontext.mozimageSmoothingEnabled = true;
//    this.icontext.webkitimageSmoothingEnabled = true;
//    this.icontext.imageSmoothingQuality = 'medium';
//    this.icontext.drawImage(this.get_background_image(), 0, 0);
};

/**
 * Вызывается менеджером, внутри animationFrame. Менеджер сам проверит корректность входных данных
 * @returns {background_layer}
 */
P.draw = function () {
    // основа для отрисовки - позиция и масштаб
    // и то и другое должен расчитать менеджер перед вызовом отрисовки  

    try {
        this.context.imageSmoothingEnabled = true;
        this.context.msimageSmoothingEnabled = true;
        this.context.mozimageSmoothingEnabled = true;
        this.context.webkitimageSmoothingEnabled = true;
        this.context.imageSmoothingQuality = 'high';
    } catch (e) {
        //do nothing
    }
    var drawing = this.get_background_image();
    if (!this.super_antialiasing_supported && this.svg_filters_supported && this.layout.calculation_result.scale < 0.5) {
        var steps = Math.min(Math.floor((1 / this.layout.calculation_result.scale) / 2.5), 5);
        if (steps > 0) {
            var sKey = ['S', steps].join('');
            var scaimg = this.step_down_cache[sKey];
            if (!scaimg) {
                scaimg = document.createElement('canvas');
                scaimg.width = this.layout.calculation_result.image.w;
                scaimg.height = this.layout.calculation_result.image.h;//_height;
                var scc = scaimg.getContext('2d');
                scc.filter = ['blur(', steps, 'px)'].join('');
                scc.drawImage(drawing, 0, 0);
                this.step_down_cache[sKey] = scaimg;
            }
            drawing = scaimg;
        }
    }
//    this.context.imageSmoothingEnabled = true;
//    this.context.msimageSmoothingEnabled = true;
//    this.context.mozimageSmoothingEnabled = true;
//    this.context.webkitimageSmoothingEnabled = true;
//    this.context.imageSmoothingQuality = 'medium';
    this.context.clearRect(0, 0, this.layout.calculation_result.viewport.w, this.layout.calculation_result.viewport.h);
//    console.log(['background-layer::draw: sx:', this.layout.calculation_result.image.offset_x, ', sy:', this.layout.calculation_result.image.offset_y,
//        ', sWidth:', this.layout.calculation_result.cap.w, ', sHeight:', this.layout.calculation_result.cap.h, ', dx:', this.layout.calculation_result.draw.x,
//        ', dy:', this.layout.calculation_result.draw.y, ', dWidth:', this.layout.calculation_result.viewport.w, ', dHeight:', this.layout.calculation_result.viewport.h,
//        ', drawing:', drawing.width, 'x', drawing.height].join(''));
    var sx = this.layout.calculation_result.image.offset_x,
            sy = this.layout.calculation_result.image.offset_y,
            sw = this.layout.calculation_result.cap.w,
            sh = this.layout.calculation_result.cap.h,
            dx = this.layout.calculation_result.draw.x,
            dy = this.layout.calculation_result.draw.y,
            dw = this.layout.calculation_result.viewport.w,
            dh = this.layout.calculation_result.viewport.h;
    var scale = this.layout.scale;
    //ширину и позицию отрисовки тоже надо скорректировать - только с коэффицентом scale?
    var dsx = 0;
    var dsy = 0;
    var dsw = 0;
    var dsh = 0;
    if (sx < 0) { //  срез левого края
        dsx = Math.abs(sx);
        var osw = sw;
        sw -= dsx; // уменьшаем ширину на величину дельты
        sx = 0; // и ставим позицию в 0    
        dsw += osw - sw; // увеличиваем дельту ширины       
    }
    if (sy < 0) {
        dsy = Math.abs(sy);
        var osh = sh;
        sh -= dsy;
        sy = 0;
        dsh += osh - sh;
    }
    if ((sy + sh) > drawing.height) {
        var y_overlap = (sy + sh) - drawing.height;
        dsh += y_overlap;
        sh -= y_overlap;
    }
    if ((sx + sw) > drawing.width) {
        var x_overlap = (sx + sw) - drawing.width;
        dsw += x_overlap;
        sw -= x_overlap;
    }

    // смещаем ночку начала отрисовки:
    dx += (dsx * scale);
    dy += (dsy * scale);
    // корректируем ширину отрисовки
    dw -= (dsw * scale);
    dh -= (dsh * scale);


    this.context.drawImage(
            /*drawable*/ drawing,
            /*sx*/ sx,
            /*sy*/ sy,
            /*sw*/ sw,
            /*sh*/ sh,
            /*dx*/ dx,
            /*dy*/ dy,
            /*dw*/ dw,
            /*dh*/ dh
            );
};


export default background_layer;