import U from './../../lib-utils';
import Filter from './../../lib-filter';
//import {central_data} from './../lib-central-data';
import projectHeader from './project-header';
import Layer from './project-layer';



function project() {
    return (project.is(this) ? this.init : project.F).apply(this, Array.prototype.slice.call(arguments));
}

var rules = {
    header: "PlainObject,DefaultObject",
    layers: "NEArray,DefaultEmptyArray"
};


var P = U.fixup_constructor(project).prototype;

P.header = null;
P.layers = null;


P.init = function (o) {
    var filter = Filter();
    var clean = filter.apply_hash(U.safeObject(o), rules);
    filter.throw_bad_value(clean);
    this.header = projectHeader(clean.header);
    this.layers = [];
    for (var i = 0; i < clean.layers.length; i++) {
        var layer = Layer(clean.layers[i]);
        if (layer && layer.isValid()) {
            this.layers.push(layer);
        }
    }
    if (!this.layers.length) {
        var nlayer=Layer({id: null, project_id: null, name: "Новый слой", points: []});        
        this.layers.push(nlayer);
    }
    return this;
};

P.isValid = function () {
    return this.header.isValid();
};

P.is_valid = P.isValid;




export default project;