import U from './../../lib-utils';
import Filter from './../../lib-filter';
import {central_data} from './../lib-central-data';

function projectHeader() {
    return (projectHeader.is(this) ? this.init : projectHeader.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(projectHeader).prototype;

P.id = null;
P.user_id = null;
P.name = null;
P.image_url = null;
P.harsh = null;
P.size = null;
P.overtone = null;
P.fill = null;
P.stroke = null;

var rules = {
    id: "Int:more=0,DefaultNull",
    user_id: "Int:more=0,DefaultNull",
    name: "String:trim=1;strip=1,NEString,DefaultNull",
    harsh: "String:trim=1;strip=1,NEString,DefaultString:default=none",
    size: "Float:more=0,DefaultNull",
    overtone: "String:trim=1;strip=1,NEString,DefaultString:default=,HTMLColor:default=ffffff00",
    fill: "String:trim=1;strip=1,NEString,DefaultString:default=,HTMLColor:default=#ffffff00",
    stroke: "String:trim=1;strip=1,NEString,DefaultString:default=,HTMLColor:default=#000000ff"
};

P.init = function (o) {
    var filter = Filter();
    var clean = filter.apply_hash(U.safeObject(o), rules);
    filter.throw_bad_value(clean);
    for (var k in clean) {
        if (Object.prototype.hasOwnProperty.call(clean, k) && !U.is_callable(clean[k])) {
            if (Object.prototype.hasOwnProperty.call(P, k) && this[k] === null) {
                this[k] = clean[k];
            }
        }
    }
    if (this.isValid()) {
        this.image_url = ['//', central_data().frontend_params.server, '/media/project/', this.id, '/project'].join('');
    }
    return this;
};


P.is_valid = function () {
    return !!(this.id && this.name && this.user_id);
};

P.isValid = P.is_valid;


export default projectHeader;