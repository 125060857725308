<template>
    <div class="local-preloader-component" v-show="visible">
        <div class="local-preloader-content">
            <div class="local-preloader-element" v-html="svgImage"></div>
        </div>
    </div>
</template>
<style   lang="scss">
    .local-preloader-component {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        * {
            user-select: none;
        }
    }

    .local-preloader-content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        background: rgba(100,100,100,.3);
    }

    .local-preloader-element {
        width: 2em;
        height: 2em;
        line-height: 0;
        pointer-events: none;
        svg {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }

</style>
<script>
    import svgImage from '!!raw-loader!@/svg/common/preloader.svg';
    export default {
        name: 'local-preloader',
        data: function () {
            return {
                visible: false,
                counter: 0,
                svgImage: svgImage
            };
        },
        props: {
            shown: {
                type: Boolean,
                'default': false
            }
        },
        methods: {
            show: function () {
                this.$.data.counter += 1;
                if (this.$.data.counter) {
                    this.$.data.visible = true;
                }
            },
            hide: function () {
                this.$.data.counter -= 1;
                if (this.$.data.counter < 0) {
                    this.$.data.counter = 0;
                }
                if (this.$.data.counter === 0) {
                    this.$.data.visible = false;
                }
            }
        },
        mounted: function () {
            if (this.$props.shown) {
                this.$.data.visible = true;
            }
        }
    }
</script>

