<template>
    <div class="plan-builder">
        <div class="plan-builder-content">
            <plan-display />
            <float-panel id="tool-selector" title="Инстр." dx="0" dy="0" dw="5" dh="100">
                <tool-selector />
            </float-panel>
            <float-panel id="object-props" title="Свойства" dx="90" dy="0" dw="10" dh="100">
                <prop-control />
            </float-panel>            
        </div>
        <preloader ref="loader" :show="preloader"/>
    </div>
</template>
<style lang="scss">

    .plan-builder {
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        .plan-builder-content{
            box-sizing: border-box;
            width:100%;
            height: 100%;
            overflow: hidden;
            position:relative;
        }
    }

</style>
<script>
    import floatPanel from './floatPanel.vue';
    import toolSelector from './tool-selector.vue';
    //import layerSelector from './layerSelector.vue';
    import planDisplay from './planDisplay.vue';
    import propControl from './prop-control.vue';
    import preloader from '@/lib/vendor/eve/components/local-preloader.vue';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import events from '@/lib/vendor/eve/planhub/lib-events';
    export default {
        name: 'plan-builder',
        components: {
            floatPanel: floatPanel,
            toolSelector: toolSelector,
            preloader: preloader,
            planDisplay: planDisplay,
            propControl: propControl
                    //  objectProps: objectProps,
                    // levelList: levelList,

        },
        props: {
            id: {
                type: Number,
                'default': null
            }
        },
        data: function () {
            return {
                preloader: false
            };
        },
        methods: {
            showLoader: function () {
                try {
                    this.$refs.loader.show();

                } catch (e) {
                    this.$data.preloader = true;
                }
            },
            hideLoader: function () {
                try {
                    this.$refs.loader.hide();
                } catch (e) {
                    this.$data.preloader = false;
                }
            },
            setup_header: function () {
                central_data().set_ui_state('APP-HEADER', {
                    title: "Проект \"" + central_data().project.header.name + '"',
                    buttons: [
                        {
                            hint: "Сохранить",
                            message: 'project-save',
                            icon_html: require('!!raw-loader!@/svg/common/flop.svg').default
                        },
                        {
                            hint: "Скачать",
                            message: 'project-down',
                            icon_html: require('!!raw-loader!@/svg/common/download.svg').default
                        }
                    ]
                });
            },
            do_save: function () {
                this.showLoader();
                central_data().save_project();
            },
            do_down: function () {
                this.showLoader();
                central_data().create_drawing(this.down_ready.bindTo(this));
            },
            down_ready: function (dataUrl) {
                var link = document.createElement('a');
                link.download = [central_data().project.header.name, '.jpg'].join('');
                link.href = dataUrl;
                link.style.display = 'none';
                var bd = document.getElementsByTagName('body');
                if (bd.length) {
                    bd.item(0).appendChild(link);
                }
                link.click();
                window.setTimeout(function () {
                    if (bd.length) {
                        bd[0].removeChild(link);
                    }
                });
                this.hideLoader();
            }


        },
        created: function () {
            central_data().lem.clear_event('project-save');
            central_data().lem.clear_event('project-down');
            central_data()
                    .on(events._PROJECT_CHANGED, this, function () {
                        this.showLoader();
                        central_data().load_project(central_data().project_id);
                    })
                    .on(events._PROJECT_LOAD_FAIL, this, this.hideLoader.bindTo(this))
                    .on(events._PROJECT_LOAD_COMPLETE, this, this.hideLoader.bindTo(this))
                    .on(events._PROJECT_LOAD_COMPLETE, this, this.setup_header.bindTo(this))
                    .on('project-save', this, this.do_save)
                    .on('project-down', this, this.do_down);
        },
        mounted: function () {
            this.showLoader();
            if (this.$props.id) {
                central_data().load_project(this.$props.id);
            }
        }
    }
</script>