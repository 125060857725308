import U from './../../lib-utils';
import Filter from './../../lib-filter';
//import {central_data} from './../lib-central-data';
import layerPoint from './layer-point';


var rules = {
    id: "Int:more=0,DefaultNull",
    project_id: "Int:more=0,DefaultNull",
    name: "String:trim=1;strip=1,DefaultNull",
    sort: 'Int,DefaultInt:default=0',
    visible: 'Boolean,DefaultBool:default=1',
    points: 'NEArray,DefaultEmptyArray'
};


function projectLayer() {
    return (projectLayer.is(this) ? this.init : projectLayer.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(projectLayer).prototype;


P.id = null;
P.project_id = null;
P.name = null;
P.sort = null;
P.visible = null;
P.uid = null;
P.points = null;

P.init = function (o) {
    var filter = Filter();
    var clean = filter.apply_hash(U.safeObject(o), rules);
    filter.throw_bad_value(clean);
    for (var k in clean) {
        if (Object.prototype.hasOwnProperty.call(clean, k) && !U.is_callable(clean[k])) {
            if (Object.prototype.hasOwnProperty.call(P, k) && this[k] === null) {
                this[k] = clean[k];
            }
        }
    }
    this.points = [];
    for (var i = 0; i < clean.points.length; i++) {
        var point = layerPoint(clean.points[i]);
        if (point && point.isValid()) {
            this.points.push(point);
        }
    }
    this.uid = U.get_uuid();
    return this;
};


P.isValid = function () {
    return !!(this.id && this.project_id && this.name);
};
P.is_valid = P.isValid;


P.get_point_by_uid = function (uid) {
    uid = U.NEString(uid, null);
    if (uid) {
        for (var i = 0; i < this.points.length; i++) {
            if (this.points[i].uid === uid) {
                return this.points[i];
            }
        }
    }
    return null;
};


P.remove_point_by_uid = function (c) {
    c = U.NEString(c, null);
    if (c) {
        var p = this.get_point_by_uid(c);
        if (p) {
            var index = this.points.indexOf(p);
            if (index >= 0) {
                this.points = this.points.slice(0, index).concat(this.points.slice(index + 1));
            }
        }
    }
};


export default projectLayer;