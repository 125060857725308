<template>
    <div class="tool-selector">
        <div class="tool-selector-select">
            <input type="text" readonly="readonly" v-model="category_name" @click="select_category" :title="category_name"/>
            <input type="hidden" v-model="category_id" />
        </div>
        <div class="tool-selector-list" >  
            <template v-if="!!error_message">
                <div class="tool-selector-error">
                    <div class="tool-selector-error-text" v-text="error_message"></div>
                </div>
            </template>
            <template v-if="!error_message">
                <div class="tool-selector-items" :key="c">
                    <template v-for="(item,index) in items" :key="item.id">                
                        <div class="tool-selector-item" v-bind:class="is_selected_tool(item)?'tool-selector-item-selected':''" v-bind:id="'tool-selector-tool-id-'+item.id" :data-index="index" :data-id="item.id" :title="item.name" @click="select_tool($event,item)">
                            <img :src="item.image_url" />
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <preloader ref="loader"  />    
        <treeSelector ref="selector" />
    </div>
</template>
<style lang="scss">
    .tool-selector {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        .tool-selector-select {
            height: 1.5em;
            max-height: 1.5em;
            min-height: 1.5em;
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            input[type=text] {
                width: 100%;
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                border: 1px solid silver;
                border-radius: 0;
                box-shadow: none;
                padding: 0 .125em;
                color: dimgray;
                background: whitesmoke;
                outline: none;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }

        }
        .tool-selector-list {
            box-sizing: border-box;
            height: 100%;
            overflow: auto;
            .tool-selector-error {
                box-sizing: border-box;
                padding: .5em .25em;
                color: crimson;
                .tool-selector-error-text {
                    font-size: .85em;
                    white-space: break-spaces;
                    overflow-wrap: break-word;
                }
            }
            .tool-selector-items {
                box-sizing: border-box;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                .tool-selector-item {
                    box-sizing: border-box;
                    width: 48px;
                    max-width: 48px;
                    height: 48px;
                    max-height: 48px;
                    min-height: 48px;
                    min-width: 48px;
                    border: 1px solid silver;
                    padding: .25em;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    margin: 3px;
                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                    &.tool-selector-item-selected {
                        border: 2px solid dimgray;
                        background: silver;
                    }
                }
            }
        }
    }

</style>
<script>
    import U from '@/lib/vendor/eve/lib-utils';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import axios from 'axios';
    import preloader from '@/lib/vendor/eve/components/local-preloader.vue';
    import treeSelector from './tree-selector.vue';
    import tool from '@/lib/vendor/eve/planhub/tool.js';
    import events from '@/lib/vendor/eve/planhub/lib-events.js';
    export default {
        name: 'tool-selector',
        components: {
            treeSelector: treeSelector,
            preloader: preloader
        },
        data: function () {
            return {
                category_name: null,
                category_id: null,
                error_message: null,
                items: [],
                c: 0
            };
        },
        methods: {
            showLoader: function () {
                this.$refs.loader.show();
            },
            hideLoader: function () {
                this.$refs.loader.hide();
            },
            select_category: function () {
                this.$refs.selector.show(this.on_tree_selected.bindTo(this));
            },
            on_tree_selected: function (category) {
                var id = U.IntMoreOr(category.id, 0, null);
                var name = U.safeArray(category.path).concat([category.name]).join('.');
                this.$data.category_name = name;
                this.$data.category_id = id;
                try {
                    var store = U.safeObject(JSON.parse(window.localStorage.getItem('tool-selector-ct')));
                    store.category_name = name;
                    store.category_id = id;
                    window.localStorage.setItem('tool-selector-ct', JSON.stringify(store));
                } catch (e) {
                    console.log(e);
                }
                this.load_elements();
            },
            load_elements: function () {
                var id = U.IntMoreOr(this.$data.category_id, 0, null);
                this.$data.items = [];
                this.$data.c += 1;
                if (id) {
                    var key = ['#', id].join('');
                    var cache = U.safeObject(central_data().cache.tool_selector);
                    if (Object.prototype.hasOwnProperty.call(cache, key)) {
                        this.$data.error_message = null;
                        this.$data.items = U.safeArray(cache[key]);
                        this.$data.c += 1;
                    } else {
                        this.showLoader();
                        axios.get(central_data().mk_api_url({action: 'get_tools_of', id: id}, 'info'))
                                .then(this.on_load_response.bindTo(this))
                                .catch(this.on_load_fail.bindTo(this))
                                .then(this.hideLoader.bindTo(this));
                    }
                }
            },
            on_load_response: function (rq) {
                if (rq) {
                    if (U.isObject(rq.data)) {
                        if (rq.data.status === 'ok') {
                            return this.on_load_success(rq.data.items);
                        }
                        if (rq.data.status === 'error') {
                            return this.on_load_fail(rq.data.error_info.message);
                        }
                    }
                    return this.on_load_fail('bad server response');
                }
            },
            on_load_fail: function (ms) {
                if (ms instanceof Error) {
                    ms = ms.message;
                }
                this.$data.error_message = ms;
                this.$data.c += 1;
            },
            on_load_success: function (pmi) {
                pmi = U.safeArray(pmi);
                var tools = [];
                for (var i = 0; i < pmi.length; i++) {
                    var t = tool(pmi[i]);
                    if (t && t.isValid()) {
                        tools.push(t);
                    }
                }
                var id = U.IntMoreOr(this.$data.category_id, 0, null);
                if (id) {
                    var key = ['#', id].join('');
                    var cache = U.safeObject(central_data().cache.tool_selector);
                    cache[key] = tools;
                    central_data().cache.tool_selector = cache;
                }
                this.$data.error_message = null;
                this.$data.items = tools;
                this.$data.c += 1;
            },
            is_selected_tool: function (item) {
                return !!(central_data().selected_tool && central_data().selected_tool.id === item.id);
            },
            select_tool: function (event, item) {
                if (this.is_selected_tool(item)) {
                    central_data().set_selected_tool(null);
                } else {
                    item.permanent = event.shiftKey;
                    central_data().set_selected_tool(item);
                }
            },
            on_tool_changed: function (evt, em, nTool, oTool) {
                if (oTool) {
                    var nn = document.getElementById(['tool-selector-tool-id-', oTool.id].join(''));
                    if (nn) {
                        nn.classList.remove('tool-selector-item-selected');
                    }
                }
                if (nTool) {
                    var n = document.getElementById(['tool-selector-tool-id-', nTool.id].join(''));
                    if (n) {
                        n.classList.add('tool-selector-item-selected');
                    }
                }
            }
        },
        created: function () {
            central_data().lem.on(events._TOOL_CHANGED, this, this.on_tool_changed);


        },
        mounted: function () {
            try {
                var store = JSON.parse(window.localStorage.getItem('tool-selector-ct'));
                if (U.isObject(store)) {
                    var category_name = U.NEString(store.category_name, null);
                    if (category_name) {
                        var category_id = U.intMoreOr(store.category_id, 0, null);
                        if (category_id) {
                            this.$data.category_name = category_name;
                            this.$data.category_id = category_id;
                            this.load_elements();
                        }
                    }
                }

            } catch (e) {
                console.log(e);
            }
        }
    }
</script>
