<template>
    <div class="project-edit-component">
        <div class="project-edit-content">
            <div class="project-edit-backdrop">
                <div class="project-edit-window">
                    <div class="project-edit-form">
                        <div class="project-editp-form-flex">
                            <div class="project-edit-rows">
                                <div class="project-edit-row">
                                    <label for="project-edit-name">Наименование</label>
                                    <input type="text" v-model="name" id="project-edit-name"/>
                                </div>
                                <div class="project-edit-row">
                                    <label for="project-edit-sort">Сортировка</label>
                                    <input type="text" v-model="sort" id="project-edit-sort" class="project-edit-ra-field"  @change="onsort"/>
                                </div>
                            </div>
                            <div class="project-edit-image">
                                <imageField ref="image"/>
                            </div>
                        </div>
                    </div>
                    <div class="project-edit-footer">
                        <router-link to="/" class="project-edit-button project-edit-button-cancel">Отмена</router-link>
                        <div class="project-edit-button project-edit-button-save" @click="save">Сохранить</div>
                    </div>
                    <preloader ref="loader" />
                </div>
            </div>
        </div>
    </div>                  
</template>
<style lang="scss">
    .project-edit-component,.project-edit-content,.project-edit-backdrop  {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .project-edit-backdrop {
        background: whitesmoke;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .project-edit-window {
            background: white;
            box-shadow: 3px 3px 10px silver;
            padding: 1em;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            max-width: 480px;
            width: 100%;
            position: relative;
            max-height: 90%;
            .project-edit-footer {
                box-sizing: border-box;
                height: 3em;
                max-height: 3em;
                min-height: 3em;
                margin-top: .5em;
                border-top: 1px solid whitesmoke;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding-top: .5em;
                .project-edit-button {
                    border: 1px solid;
                    margin-left: .5em;
                    line-height: 2.5em;
                    padding: 0 1.75em;
                    cursor: pointer;
                    text-decoration: none;
                    user-select: none;
                    transition: all .3s;
                    &.project-edit-button-save {
                        border-color: green;
                        color: white;
                        background: green;
                        &:hover {
                            color: green;
                            background: white;
                        }
                    }
                    &.project-edit-button-cancel {
                        color: white;
                        background: orange;
                        border-color: orange;
                        &:hover {
                            background: white;
                            color: orange;
                        }
                    }
                }
            }
            .project-edit-form {
                box-sizing: border-box;
                max-height: 100%;
                height: 100%;

                .project-editp-form-flex {
                    box-sizing: border-box;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .project-edit-image {
                        box-sizing: border-box;
                        height: 9em;
                        line-height: normal;
                        width: 9em;
                        max-width: 9em;
                        min-width: 9em;
                        overflow: hidden;
                    }

                    .project-edit-rows {
                        width: 100%;
                        box-sizing: border-box;
                        margin-right: 1em;
                    }
                }


                .project-edit-row {
                    box-sizing: border-box;
                    margin-bottom: .5em;
                    input[type=text] {
                        box-sizing: border-box;
                        padding: 0 .5em;
                        width: 100%;
                        height: 3em;
                        border: 1px solid silver;
                        border-radius: 0;
                        outline: none;
                        box-shadow: none;
                        transition: border-color .3s;
                        background: white;
                        &.project-edit-ra-field{
                            text-align:right;
                        }
                        &:focus {
                            border-color: dimgray;
                        }
                    }
                    label {
                        display: block;
                        margin-bottom: .125em;
                    }
                }
            }
        }
    }

</style>
<script>
    import preloader from '@/lib/vendor/eve/components/local-preloader.vue';
    import axios from 'axios';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import U from '@/lib/vendor/eve/lib-utils';
    import imageField from './imageField.vue';
    import router from '@/router/index';
    import Filter from '@/lib/vendor/eve/lib-filter';
    import {alert} from '@/lib/vendor/eve/lib-alert';
    function get_default_data() {
        return {
            id: null,
            name: '',
            sort: 0,
            harsh: ''
        };
    }

    export default{
        name: 'project-edit',
        data: function () {
            return get_default_data();
        },
        components: {
            preloader: preloader,
            imageField: imageField
        },
        props: {
            'id': {

                type: Number,
                default: null
            }
        },
        methods: {
            showLoader: function () {
                this.$refs.loader.show();
            },
            hideLoader: function () {
                this.$refs.loader.hide();
            },
            load: function (id) {
                id = U.IntMoreOr(this.$props.id, 0, null);
                if (id) {
                    this.showLoader();
                    axios.get(central_data().mk_api_url({action: 'project_header', id: id}))
                            .then(this.on_load_response.bindTo(this))
                            .catch(this.on_load_fail_fatal.bindTo(this))
                            .then(this.hideLoader.bindTo(this));
                } else {
                    U.deep_clone(get_default_data(), this.$data);
                    this.$refs.image.set_image(null);
                }
            },
            on_load_response: function (r) {
                if (r) {
                    if (U.isObject(r.data)) {
                        if (r.data.status === 'ok') {
                            return this.load_success(r.data.project);
                        }
                        if (r.data.status === 'error') {
                            return this.on_load_fail_fatal(r.data.error_info.message);
                        }
                    }
                    return this.on_load_fail_fatal('bad server response');
                }
            },
            on_load_fail_fatal: function (x) {
                this.on_network_fail(x);
                router.replace('/');
            },
            on_network_fail: function (x) {
                this.show_message(x);
            },
            show_message: function (x) {
                if (x instanceof Error) {
                    x = x.message;
                }
                alert({
                    text: x,
                    title: 'Ошибка',
                    icon: 'preset:stop',
                    timeout: 5000,
                    close: false,
                    show: true,
                    style: 'crimson'
                });
            },
            load_success: function (po) {
                U.deep_clone(po, this.$data);
                central_data().set_ui_state('APP-HEADER', {
                    title: ("Редактрование проекта \"" + this.$data.name + "\""),
                    buttons: []
                });
                this.$refs.image.set_image(po.image, po.harsh);
            },
            onsort: function () {
                var sort = U.IntOr(this.$data.sort, 0);
                this.$data.sort = sort;
            },
            save: function () {
                this.showLoader();
                try {
                    var data = Filter().apply_hash_xml(this.$data, require(`!!raw-loader!./validators/project-edit.xml`).default, 'project/export');
                    Filter().throw_bad_value(data);
                    var fd = new FormData();
                    fd.append('data', JSON.stringify(data));
                    fd.append('image_method', this.$refs.image.get_operation());
                    fd.append('image_data', this.$refs.image.get_image());
                    fd.append('action', 'put_project');
                    axios.post(central_data().mk_api_url(), fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                            .then(this.on_post_response.bindTo(this))
                            .catch(this.on_network_fail.bindTo(this))
                            .then(this.hideLoader.bindTo(this));
                } catch (e) {
                    this.hideLoader();
                    this.show_message(e);
                }
            },

            on_post_response: function (r) {
                if (r) {
                    if (U.isObject(r.data)) {
                        if (r.data.status === 'ok') {
                            return this.on_post_success();
                        }
                        if (r.data.status === 'error') {
                            return this.on_network_fail(r.data.error_info.message);
                        }
                    }
                    return this.on_network_fail('bad server response');
                }
            },
            on_post_success: function () {
                router.replace("/");
            }
        },
        mounted: function () {
            var id = U.IntMoreOr(this.$props.id, 0, null);
            central_data().set_ui_state('APP-HEADER', {
                title: (id ? "Редактрование проекта" : "Новый проект"),
                buttons: []
            });
            this.load(this.$props.id);
        }
    }
</script>
