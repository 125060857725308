import U from './../lib-utils';
import resizeMonitor from './../lib-resize-monitor';
import {centralData} from './lib-central-data';
import background_layer from './backgroundLayer';
import selectionLayer from './selectionLayer';
import svgLayer from './svgLayer';
import layer_calculator from './layerCalculator';
import events from './lib-events';
require('./style/drawable_layers.scss');

var MAX_SCALE = 20;
var SCALE_FACTOR = 1.1;
/*
 * Координатор набора слоев
 * здесь храним масштаб и производим вычисления
 * здесь же - релэй до centralData
 * здесь же - процессор тачей / кликов / мовов
 * начнем с одиночного слоя, пока не выделяя абстракную сущность.
 */


function _on_resize() {
    if (this.layout_height !== null && this.layout_width !== null) {
        var self = this;
        if (this.calculation_result) {
            window.requestAnimationFrame(function () {
                for (var iResize = 0; iResize < self.layers.length; iResize++) {
                    self.layers[iResize].resize();
                }
                for (var iDraw = 0; iDraw < self.layers.length; iDraw++) {
                    self.layers[iDraw].draw();
                }
            });
        }
    }
}

function on_resize() {
    if (this._resize_to_id) {
        //window.cancelAnimationFrame(this._resize_af_id);
        window.clearTimeout(this._resize_af_id);
        this._resize_to_id = null;
    }
    this.layout_width = U.FloatMoreOr(this.handle.offsetWidth, 0, 1);
    this.layout_height = U.FloatMoreOr(this.handle.offsetHeight, 0, 1);
    this.recalculate();
    var self = this;
    this._resize_to_id = window.setTimeout(function () {
        _on_resize.call(self);
    }, 0);

}

function render() {
    this.recalculate();
    if (this.calculation_result) {

        for (var iDraw = 0; iDraw < this.layers.length; iDraw++) {
            this.layers[iDraw].draw();
        }

    }
}

function create_layers() {
    //background layer
    var bLayer = background_layer(this);
    this.layers.push(bLayer);
    this.layer_container.appendChild(bLayer.handle);
    var sLayer = svgLayer(this);
    this.layers.push(sLayer);
    this.layer_container.appendChild(sLayer.handle);
    //resize layer цепляем прямо к хэндлу
    var selLayer = selectionLayer(this);
    this.layers.push(selLayer);
    this.handle.appendChild(selLayer.handle);
}
//<editor-fold defaultstate="collapsed" desc="wheel event">
function on_wheel(event) {
    /* eslint-disable */
    if (this.centralData.image_canvas && this.centralData.image_canvas.width) {
        if (Math.abs(event.deltaY) >= 1) {
            var sign = Math.sign(event.deltaY) * -1;
            scale_step.apply(this, [sign, false, {x: event.offsetX, y: event.offsetY}]);
            return;
        }
    }
}

function scale_step(steps, animate, around) {
    steps = U.IntOr(steps, 1);
    if (steps !== 0) {
        animate = U.any_bool(animate, false);
        var min_scale = Math.min((100 / this.centralData.image_canvas.width), (100 / this.centralData.image_canvas.height));
        var factor = Math.pow(SCALE_FACTOR, steps);
        var new_scale = (Math.max(Math.min(this.scale * factor, MAX_SCALE), min_scale));
        var delta_scale = new_scale - this.scale;
        var target_scale = U.FloatOr((this.scale + delta_scale).toFixed(3), 1.0);
        if (U.is_object(around)) {
            // нам нужны координаты эвента - масштабирование должно быть вокруг этой точки            
            // координаты в пикселях вьюпорта совпадают с координатами эвента.
            var vppx = this.calculator.translate_viewport_px(around.x, around.y, this.layout_width, this.layout_height, this.scale, this.center_x, this.center_y);
            var vppxs = this.calculator.translate_viewport_px(around.x, around.y, this.layout_width, this.layout_height, target_scale, this.center_x, this.center_y);
            var dx = vppxs.image.pcu.x - vppx.image.pcu.x;
            var dy = vppxs.image.pcu.y - vppx.image.pcu.y;
            var new_pos_x = this.center_x - dx;
            var new_pos_y = this.center_y - dy;
            if (animate) {
                animate_scale_pos.apply(this, [target_scale, new_pos_x, new_pos_y]);
            } else {
                set_scale_pos.apply(this, [target_scale, new_pos_x, new_pos_y]);
            }
        } else {
            if (animate) {
                animate_scale_pos.apply(this, [target_scale, this.center_x, this.center_y]);
            } else {
                set_scale_pos.apply(this, [target_scale, this.center_x, this.center_y]);
            }
        }
    }

}
//</editor-fold>

function animate_scale_pos(scale, posx, posy, callback) {
    scale = U.FloatOr(scale, this.scale);
    posx = U.FloatOr(posx, this.center_x);
    posy = U.FloatOr(posy, this.center_y);
    if (this._anim_frame) {
        window.cancelAnimationFrame(this._anim_frame);
    }
    var delta_x = posx - this.center_x;
    var delta_y = posy - this.center_y;
    var delta_s = scale - this.scale;
    var current_x = this.center_x;
    var current_y = this.center_y;
    var current_s = this.scale;
    var step_x = delta_x / 100;
    var step_y = delta_y / 100;
    var step_s = delta_s / 100;
    var self = this;
    var n = performance.now();
    var duration = 50;
    var run = function (time) {
        var offset = Math.max(time - n, 0);
        var completion = Math.min(offset / (duration / 100), 100);
        var step_pos_x = current_x + step_x * completion;
        var step_pos_y = current_y + step_y * completion;
        var step_scale = current_s + step_s * completion;
        set_scale_pos.apply(self, [step_scale, step_pos_x, step_pos_y]);
        if (completion < 100) {
            self._anim_frame = requestAnimationFrame(run);
        } else {
            set_scale_pos.apply(self, [scale, posx, posy]);
            if (U.is_callable(callback)) {
                callback();
            }
        }
    };
    this._anim_frame = requestAnimationFrame(run);

}

function set_scale_pos(scale, posx, posy) {
    this.center_x = posx;
    this.center_y = posy;
    this.scale = scale;
    window.requestAnimationFrame(render.bind_to(this));
}





//<editor-fold defaultstate="collapsed" desc="mousedown-move">
function on_mouse_down(event) {
    //мовинг точек не процессим - тоесть либо мы кудато кликаем, либо двигаем    
    if (event.button === 0) {
        this._on_mouse_down_start = {x: event.clientX, y: event.clientY};
        this._on_mouse_down_start_center = {x: this.center_x, y: this.center_y};
        this.event_inceptor.addEventListener('mousemove', on_mouse_down_waitmove.bind_to(this));
        this.event_inceptor.addEventListener('mouseup', on_mouse_up_waitmove.bind_to(this));
        // это может быть как мовинг, так и клик
        // клик - задача интерцептор, тоесть обрабатывает клик только кто-то один
    }
}

function on_mouse_down_waitmove(event) {
    var dx = Math.max(this._on_mouse_down_start.x, event.clientX) - Math.min(this._on_mouse_down_start.x, event.clientX);
    var dy = Math.max(this._on_mouse_down_start.y, event.clientY) - Math.min(this._on_mouse_down_start.y, event.clientY);
    var hy = Math.sqrt(dx * dx + dy * dy);
    if (hy > 5) {
        this.event_inceptor.removeEventListener('mousemove', on_mouse_down_waitmove.bind_to(this));
        this.event_inceptor.removeEventListener('mouseup', on_mouse_up_waitmove.bind_to(this));
        document.addEventListener('mouseup', on_mouse_up_waitmove_incept.bind_to(this));
        document.addEventListener('mousemove', on_mouse_down_whilemove.bind_to(this));
        this.event_inceptor.addEventListener('click', on_move_abort_click.bind_to(this), true);
        on_mouse_down_whilemove.call(this, event);
    }
}

function on_mouse_up_waitmove(event) {
    this.event_inceptor.removeEventListener('mousemove', on_mouse_down_waitmove.bind_to(this));
    this.event_inceptor.removeEventListener('mouseup', on_mouse_up_waitmove.bind_to(this));
    document.removeEventListener('mouseup', on_mouse_up_waitmove_incept.bind_to(this));
    document.removeEventListener('mousemove', on_mouse_down_whilemove.bind_to(this));


}

function on_move_abort_click(event) {
    event.preventDefault();
    event.stopPropagation();
    this.event_inceptor.removeEventListener('click', on_move_abort_click.bind_to(this), true);
}

function on_mouse_up_waitmove_incept(event) {
    on_mouse_up_waitmove.call(this, event);

}

function on_mouse_down_whilemove(event) {
    if (this.calculator) {
        var dxPx = event.clientX - this._on_mouse_down_start.x;
        var dyPx = event.clientY - this._on_mouse_down_start.y;
        // translate dxPx && dyPx on layout to dxPCU && dyPCU on image
        //viewportWidth, viewportHeight, scale, center_x, center_y
        var dPcu = this.calculator.translate_viewport_px_to_image_pcu(dxPx, dyPx, this.scale);
        set_scale_pos.apply(this, [this.scale, this._on_mouse_down_start_center.x - dPcu.x, this._on_mouse_down_start_center.y - dPcu.y]);
    }

}
//</editor-fold>
//<editor-fold defaultstate="collapsed" desc="click event (desktop)">
function on_mouse_click(event) {
    if (this.centralData.image_canvas && this.centralData.selected_tool) {
        if (this.calculator) {
            this.calculator.extend_event_with_image_pcu(event, this.layout_width, this.layout_height, this.scale, this.center_x, this.center_y, 'offset');
            this.centralData.place_tool(event);
            return;
        }

    }
    if (this.calculator) {
        this.calculator.extend_event_with_image_pcu(event, this.layout_width, this.layout_height, this.scale, this.center_x, this.center_y, 'offset');
        for (var i = this.layers.length - 1; i >= 0; i--) {
            if (this.layers[i].on_click_tap(event)) {
                return;
            }
        }
    }
}
//</editor-fold>
//<editor-fold defaultstate="collapsed" desc="mousemove-simple">
function on_mouse_move(event) {
    if (this.calculator) {
        this.calculator.extend_event_with_image_pcu(event, this.layout_width, this.layout_height, this.scale, this.center_x, this.center_y, 'offset');
        var self = this;
        window.requestAnimationFrame(function () {
            for (var i = 0; i < self.layers.length; i++) {
                self.layers[i].on_hover(event);
            }
        });
    }
}
//</editor-fold>




function layerLayout() {
    return (layerLayout.is(this) ? this.init : layerLayout.F).apply(this, Array.prototype.slice.call(arguments));
}
var P = U.fixup_constructor(layerLayout).prototype;

P.parent_node = null;
P.handle = null;
P.layer_container = null;
P.layers = null;
P.event_inceptor = null;
P.centralData = null;
P.layout_width = 1;
P.layout_height = 1;
P.scale = 1.0; //  масштаб
P.center_x = 50; // точка в pcu, которая всегда должна быть в центре
P.center_y = 50; // она может быть сдвинута при мовинге карты
P.calculation_result = null;
P.calculator = null;

P.init = function (pn) {
    if (!U.is_dom(pn)) {
        throw new Error('-lib-map:layerLayout requires DOMElement');
    }
    this.parent_node = pn;
    this.centralData = centralData();
    this.handle = document.createElement('div');
    this.handle.classList.add('lib-map-layer-layout');
    this.layer_container = document.createElement('div');
    this.layer_container.classList.add('lib-map-layer-layout-layers');
    this.handle.appendChild(this.layer_container);
    this.event_inceptor = document.createElement('div');
    this.event_inceptor.classList.add('lib-map-layer-layout-event-inceptor');
    this.handle.appendChild(this.event_inceptor);
    this.layers = [];
    create_layers.call(this);
    resizeMonitor().on(this.handle, on_resize.bind_to(this));
    this.parent_node.appendChild(this.handle);
    if (U.is_mobile()) {
        this.attach_events_mobile();
    } else {
        this.attach_events_desktop();
    }
    this.centralData.lem.on(events._PROJECT_LOAD_COMPLETE, this, this.on_image_ready);
    document.addEventListener('keydown', this._g_keydown.bindTo(this));

    return this;
};


P._g_keydown = function (event) {
    if (event.keyCode === 46) {
        if (event.target.tagName.toLowerCase() === 'body') {
            if (this.centralData.selection) {
                this.centralData.remove_selected();
            }
        }
    }
};


P.clear = function () {
    for (var i = 0; i < this.layers.length; i++) {
        this.layers[i].clear();
    }
    this.calculation_result = null;
    this.calculator = null;
    this.scale = 1.0;
    this.center_x = 50;
    this.center_y = 50;
};

P.on_data_ready = function () {
    this.clear();
    for (var i = 0; i < this.layers.length; i++) {
        this.layers[i].on_data_ready();// уведомляем все слои о готовности данных
    }
};

P.on_image_ready = function () {
    this.clear();
    if (this.centralData.image_canvas) {
        this.recalculate();
        for (var i = 0; i < this.layers.length; i++) {
            this.layers[i].on_image_ready();// уведомляем все слои о готовности изображения
        }
        on_resize.call(this);
        window.requestAnimationFrame(this.on_image_ready_after.bind_to(this));
    } else {
        this.clear();
    }
};

P.animate_center_fit = function (callback) {
    if (this.layout_width && this.layout_height && this.centralData.image_canvas && this.calculator) {
        var scale = this.calculator.calculate_fit(this.layout_width, this.layout_height);
        animate_scale_pos.apply(this, [U.FloatOr(scale.toFixed(3), 1.0), 50, 50, callback]);
    }
};

P.animate_to = function (scale, x, y, callback) {
    if (this.layout_width && this.layout_height && this.centralData.image_canvas && this.calculator) {
        animate_scale_pos.apply(this, [U.FloatOr(scale.toFixed(3), 1.0), U.FloatOr(x, 50), U.FloatOr(y, 50), callback]);
    }
};

P.set_center_fit = function () {
    if (this.layout_width && this.layout_height && this.centralData.image_canvas && this.calculator) {
        var scale = this.calculator.calculate_fit(this.layout_width, this.layout_height);
        set_scale_pos.apply(this, [U.FloatOr(scale.toFixed(3), 1.0), 50, 50]);
    }
};

P.on_image_ready_after = function () {
    this.animate_center_fit(this._initial_animation_complete.bind_to(this));
};

P._initial_animation_complete = function () {
    //do nothing yet  
    this._layer_check_hilight();
};

P._layer_check_hilight = function () {
    if (this.centralData.polygon_hilight || this.centralData.point_hilight) {
        for (var i = 0; i < this.layers.length; i++) {
            this.layers[i].check_hilight_state(this.centralData);
        }
    }
    // ресет подсветки произведет тот кому он адресован
};

P.zoom_in = function (animate) {
    scale_step.apply(this, [3, U.any_bool(animate, false), null]);
};

P.zoom_out = function (animate) {
    scale_step.apply(this, [-3, U.any_bool(animate, false), null]);
};



P.recalculate = function () {
    if (this.centralData.image_canvas) {
        if (!this.calculator) {
            this.calculator = layer_calculator(this.centralData.image_canvas.width, this.centralData.image_canvas.height);
        }
        this.calculation_result = this.calculator.calculate(this.layout_width, this.layout_height, this.scale, this.center_x, this.center_y);
    } else {
        this.calculation_result = null;
    }
};

P.attach_events_desktop = function () {
    this.event_inceptor.addEventListener('wheel', on_wheel.bind_to(this));
    this.event_inceptor.addEventListener('mousedown', on_mouse_down.bind_to(this));
    this.event_inceptor.addEventListener('mousemove', on_mouse_move.bind_to(this));
    this.event_inceptor.addEventListener('click', on_mouse_click.bind_to(this));
};
//allow child layers to propagate wheel event to layout
P.on_wheel = function (event) {
    on_wheel.call(this, event);
};

P.attach_events_mobile = function () {
    //this.event_inceptor.addEventListener('touchstart', on_touch_start.bind_to(this));   
//    this.toucher = touchDetector(this.event_inceptor, this);
};



export default layerLayout;