<template>
    <teleport to="body">
        <div class="tree-selector-backdrop" ref="handle">
            <div class="tree-selector-backdrop-flex">
                <div class="tree-selector-window">
                    <div class="tree-selector-window-title"><div class="tree-selector-window-title-text">Выберите</div></div>
                    <div class="tree-selector-body">
                        <div class="tree-selector-filter">
                            <input type="text" v-model="search" />
                        </div>
                        <div class="tree-selector-body-content" ref="body" v-html="get_content()" :key="search" @click="do_selection"></div>
                    </div>
                    <div class="tree-selector-footer">
                        <div class="tree-selector-button tree-selector-button-cancel" @click="do_close">Отмена</div>
                        <div class="tree-selector-button tree-selector-button-ok" @click="do_select">Выбрать</div>
                    </div>                        
                </div>
            </div>            
        </div>
    </teleport>
</template>
<style lang="scss">
    .tree-selector-backdrop {
        box-sizing: border-box;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,.5);
        z-index: 5;
        .tree-selector-backdrop-flex {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .tree-selector-window {
                box-sizing: border-box;
                width: 100%;
                max-width: 540px;
                max-height: 90%;
                background: white;
                border: 1px solid silver;
                overflow: hidden;
                box-shadow: 3px 3px 10px silver;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                .tree-selector-window-title {
                    height: 2em;
                    box-sizing: border-box;
                    border-bottom: 1px solid whitesmoke;
                    min-height: 2em;
                    max-height: 2em;
                    user-select:none;
                    padding: 0 .5em;
                    background: whitesmoke;
                    .tree-selector-window-title-text {
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-weight: bold;
                        user-select:none;
                    }
                }
                .tree-selector-footer {
                    box-sizing: border-box;
                    height: 3.5em;
                    min-height: 3.5em;
                    max-height: 3.5em;
                    border-top: 1px solid whitesmoke;
                    margin-top: .5em;
                    background: whitesmoke;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 0 .5em;
                    user-select:none;
                    .tree-selector-button {
                        box-sizing: border-box;
                        line-height: 2.5em;
                        border: 1px solid;
                        padding: 0 1.75em;
                        cursor: pointer;
                        margin-left: .5em;
                        transition: all .3s;
                        user-select:none;
                        &.tree-selector-button-cancel {
                            border-color: darkorange;
                            background: darkorange;
                            color: white;
                            &:hover {
                                background: white;
                                color: darkorange;
                            }
                        }
                        &.tree-selector-button-ok {
                            color: white;
                            background: green;
                            border-color: green;
                            &:hover {
                                color: green;
                                background: white;
                            }
                        }
                    }
                }
                .tree-selector-body {
                    height: 100%;
                    box-sizing: border-box;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    .tree-selector-filter {
                        height: 2.5em;
                        max-height: 2.5em;
                        min-height: 2.5em;
                        input[type=text] {
                            box-sizing: border-box;
                            border: 1px solid silver;
                            width: 100%;
                            height: 100%;
                            border-left: none;
                            border-right: none;
                            padding: 0 .5em;
                            outline: none;
                            border-radius: 0;
                            box-shadow: none;
                        }
                    }

                    .tree-selector-body-content {
                        height: 100%;
                        overflow: auto;
                        padding: .5em;
                        box-sizing: border-box;
                        min-height: 7em;
                        .tree-selector-element {
                            box-sizing: border-box;
                            user-select:none;
                            .tree-select-item-header {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: nowrap;
                                padding: .125em .5em;
                                cursor:pointer;
                                user-select:none;
                                .tree-select-header-content {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    padding-right: .5em;
                                    padding-top: .125em;
                                    padding-bottom: .125em;
                                    padding-left: .5em;
                                    .tree-select-item-icon {
                                        width: 1.25em;
                                        height: 1.25em;
                                        margin-right: .5em;
                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .tree-select-item-text {
                                        box-sizing: border-box;
                                        width: auto;
                                        white-space: nowrap;
                                        overflow: hidden;
                                    }
                                }



                            }
                            .tree-select-item-childs {
                                box-sizing: border-box;
                                padding-left: .75em;
                            }
                            &.tree-select-selected{
                                >.tree-select-item-header{
                                    >.tree-select-header-content {
                                        background: dimgray;
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<script>
    import U from '@/lib/vendor/eve/lib-utils';
    import mustache from '@/lib/vendor/mustache/mustache';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import template from '!!raw-loader!./templates/tree-selector/tree.html';

    export default {
        name: 'tree-selector',
        data: function () {
            return {
                search: ''
            };
        },
        methods: {
            escapeRegExp: function (string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },
            place_common_icons: function () {
                var d = document.getElementById('tree-selector-level-icon');
                if (!d) {
                    d = document.createElement('div');
                    d.innerHTML = require('!!raw-loader!@/svg/tree-selector/folder.svg').default;
                    d.children[0].id = 'tree-selector-level-icon';
                    d.style.display = 'none';
                    var bds = document.getElementsByTagName('body');
                    if (bds.length) {
                        bds[0].appendChild(d);
                    }
                }
            },
            get_content: function () {
                var tree = {childs: central_data().tree, check_branch: this._check_branch.bindToWP(this)};
                return mustache.render(template, tree, {template: template});
            },
            _check_branch: function (item) {
                var str = this.$data.search;
                if (str.length === 0) {
                    return true;
                }
                var rx = new RegExp([this.escapeRegExp(str)].join(''), 'i');
                return this._deep_search(item, rx);
            },
            _deep_search: function (i, r) {
                if (r.test(i.name)) {
                    return true;
                }
                for (var n = 0; n < i.childs.length; n++) {
                    if (this._deep_search(i.childs[n], r)) {
                        return true;
                    }
                }
                return false;
            },
            remove_class_recursive: function (node, className) {
                if (node.classList.contains(className)) {
                    node.classList.remove(className);
                }
                for (var i = 0; i < node.children.length; i++) {
                    this.remove_class_recursive(node.children[i], className);
                }
            },
            fund_selected_node: function (node, className) {
                if (node.classList.contains(className)) {
                    return node;
                }
                for (var i = 0; i < node.children.length; i++) {
                    var n = this.fund_selected_node(node.children[i], className);
                    if (n) {
                        return n;
                    }
                }
                return null;
            },
            show: function (cb, selected) {
                this._callback = U.isCallable(cb) ? cb : null;
                this.$refs.handle.style.display = 'block';
                this.$data.search = '';
                selected = U.IntMoreOr(selected, 0, null);
                this.remove_class_recursive(this.$refs.handle, 'tree-select-selected');
                if (selected) {
                    var e = document.getElementById('tree-select-item-id-' + selected);
                    if (e) {
                        e.classList.add('tree-select-selected');
                    }
                }
            },
            do_close: function () {
                this._callback = null;
                this.$refs.handle.style.display = 'none';
            },
            do_select: function () {
                var sn = this.fund_selected_node(this.$refs.body, 'tree-select-selected');
                if (sn) {
                    var id = U.IntMoreOr(sn.dataset.elementId, 0, null);
                    if (id) {
                        var element = this.find_tree_element(id);
                        if (element) {
                            if (this._callback) {
                                this._callback(element);
                                this.do_close();
                            }
                        }
                    }
                }
            },
            find_tree_element: function (id) {
                var tree = U.safeArray(central_data().tree);
                for (var i = 0; i < tree.length; i++) {
                    var found = this.search_tree_rec(tree[i], id);
                    if (found) {
                        return found;
                    }
                }
                return null;
            },
            search_tree_rec: function (node, id) {
                if (U.IntMoreOr(node.id, 0, null) === id) {
                    return node;
                }
                var sa = U.safeArray(node.childs);
                for (var i = 0; i < sa.length; i++) {
                    var found = this.search_tree_rec(sa[i], id);
                    if (found) {
                        return found;
                    }
                }
                return null;
            },
            search_up_node: function (node, stop) {
                if (node && node.dataset && U.IntMoreOr(node.dataset.selectKey, 0, null)) {
                    return node;
                }
                var w = node.parentElement;
                while (w && w !== stop) {
                    if (w && w.dataset && U.IntMoreOr(w.dataset.selectKey, 0, null)) {
                        return w;
                    }
                    w = w.parentElement;
                }
            },
            do_selection: function (event) {
                var node = this.search_up_node(event.target, this.$refs.body);
                if (node) {
                    var id = U.IntMoreOr(node.dataset.selectKey, 0, null);
                    this.remove_class_recursive(this.$refs.handle, 'tree-select-selected');
                    var e = document.getElementById('tree-select-item-id-' + id);
                    if (e) {
                        e.classList.add('tree-select-selected');
                    }
                }
            }
        },
        created: function () {
            this.place_common_icons();
        }
    };
</script>
