<template>
    <div class="prop-editor-h">
        <div class="prop-editor-i">
            <div class="prop-editor-panel" v-if="selection.ex">
                <div class="prop-editor-panel-h">
                    <div class="prop-editor-panel-h-t">Объект</div>
                </div>
                <div class="prop-editor-panel-b">
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-o-size">Size</label>
                        <input type="text" id="prop-editor-id-o-size" v-model="selection.size" @change="update_selection($event,'size')" @wheel="onwheels($event,'size')"/>
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-o-x">Pos</label>
                        <div class="prop-editor-panel-row-join">
                            <input type="text" id="prop-editor-id-o-x" v-model="selection.x" @change="update_selection($event,'x')" @wheel="onwheels($event,'x')"/>
                            <input type="text" id="prop-editor-id-o-y" v-model="selection.y" @change="update_selection($event,'y')" @wheel="onwheels($event,'y')"/>
                        </div>
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-o-rot">Rot</label>
                        <input type="text" id="prop-editor-id-o-rot" v-model="selection.rotate" @change="update_selection($event,'rotate')" @wheel="onwheels($event,'rotate')"/>
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-o-stroke">Stroke</label>
                        <div class="prop-editor-panel-row-join">
                            <input type="color" id="prop-editor-id-o-stroke" v-model="selection.stroke_color" @input="update_selection($event,'stroke')"/>
                            <input type="text" class="prop-editor-panel-row-join-alpha" v-model="selection.stroke_alpha" @change="update_selection($event,'stroke')" @wheel="onwheels($event,'stroke')"/>
                        </div>
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-o-fill">Fill</label>
                        <div class="prop-editor-panel-row-join">
                            <input type="color" id="prop-editor-id-o-fill" v-model="selection.fill_color" @input="update_selection($event,'fill')"/>
                            <input type="text" class="prop-editor-panel-row-join-alpha" v-model="selection.fill_alpha"  @change="update_selection($event,'fill')" @wheel="onwheels($event,'fill')" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="prop-editor-panel">
                <div class="prop-editor-panel-h">
                    <div class="prop-editor-panel-h-t">Проект</div>
                </div>
                <div class="prop-editor-panel-b">
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-p-size">Size</label>
                        <input type="text" id="prop-editor-id-p-size" v-model="project.size" @change="update_project($event,'size')" @wheel.stop.prevent="onwheelp($event,'size')" />
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-p-stroke">Stroke</label>
                        <div class="prop-editor-panel-row-join">
                            <input type="color" id="prop-editor-id-p-stroke"  v-model="project.stroke_color" @input="update_project($event,'stroke')" />
                            <input type="text" class="prop-editor-panel-row-join-alpha" v-model="project.stroke_alpha" @change="update_project($event,'stroke')" @wheel.stop="onwheelp($event,'stroke_alpha')" />
                        </div>
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-p-fill">Fill</label>
                        <div class="prop-editor-panel-row-join">
                            <input type="color" id="prop-editor-id-p-fill"  v-model="project.fill_color" @input="update_project($event,'fill')"  />
                            <input type="text" class="prop-editor-panel-row-join-alpha" v-model="project.fill_alpha" @change="update_project($event,'fill')" @wheel.stop="onwheelp($event,'fill_alpha')" />
                        </div>
                    </div>
                    <div class="prop-editor-panel-row">
                        <label for="prop-editor-id-p-overtone">Overtone</label>
                        <div class="prop-editor-panel-row-join">
                            <input type="color" id="prop-editor-id-p-overtone" v-model="project.overtone_color" @input="update_project($event,'overtone')"  />
                            <input type="text" class="prop-editor-panel-row-join-alpha" v-model="project.overtone_alpha" @change="update_project($event,'overtone')" @wheel.stop="onwheelp($event,'overtone_alpha')" />
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<style lang="scss">
    .prop-editor-h {
        box-sizing: border-box;
        overflow: auto;
        height: 100%;
        width: 100%;
        .prop-editor-i {
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            padding: .25em;
            .prop-editor-panel {
                box-sizing: border-box;
                margin-bottom: 1em;
                .prop-editor-panel-h {
                    box-sizing: border-box;
                    overflow: hidden;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: .5em;
                    .prop-editor-panel-h-t {
                        font-weight: bold;
                        font-size: .95em;
                        border-bottom: 1px solid dimgray;
                    }
                }
                .prop-editor-panel-b {
                    box-sizing: border-box;
                    width: 100%;
                    overflow: hidden;
                    .prop-editor-panel-row {
                        box-sizing: border-box;
                        width: 100%;
                        margin-bottom: .5em;
                        input[type=text] {
                            box-sizing: border-box;
                            font-family: inherit;
                            font-size: inherit;
                            width: 100%;
                            max-width: 100%;
                            min-width: 100%;
                            border: 1px solid silver;
                            border-radius: 0;
                            outline: none;
                            height: 2em;
                            text-align: right;

                        }
                        .prop-editor-panel-row-join {
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            height: 2em;
                            input[type=color] {
                                box-sizing: border-box;
                                padding: 0;
                                margin: 0;
                                height: 2em;
                                border-radius: 0;
                                outline: none;
                                cursor: pointer;
                                width: 100%;
                                border-right: none;
                                font-family: inherit;
                                font-size: inherit;

                            }
                            input[type=text] {
                                width: 50%;
                                max-width: 50%;
                                min-width: 50%;
                                font-size:.85em;
                                height:100%;
                                &:nth-child(1){
                                    border-right:none;
                                }
                                &.prop-editor-panel-row-join-alpha {
                                    width: 3em;
                                    max-width: 3em;
                                    min-width: 3em;
                                }
                            }
                        }
                    }
                }

            }
        }
    }





</style>
<script>
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import events from '@/lib/vendor/eve/planhub/lib-events';
    import U from '@/lib/vendor/eve/lib-utils';
    function parse_color(x) {
        x = U.NEString(x, '').replace(/^#/g, '').replace(/[^0-9a-f]/ig, '');
        var m1 = /^([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.exec(x);
        if (m1) {
            return {
                r: {
                    h: m1[1],
                    d: parseInt(m1[1], 16)
                },
                g: {
                    h: m1[2],
                    d: parseInt(m1[2], 16)
                },
                b: {
                    h: m1[3],
                    d: parseInt(m1[3], 16)
                },
                a: {
                    h: m1[4],
                    d: parseInt(m1[4], 16)
                }
            };
        }
        var m2 = /^([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.exec(x);
        if (m2) {
            return {
                r: {
                    h: m2[1],
                    d: parseInt(m2[1], 16)
                },
                g: {
                    h: m2[2],
                    d: parseInt(m2[2], 16)
                },
                b: {
                    h: m2[3],
                    d: parseInt(m2[3], 16)
                },
                a: {
                    h: 'ff',
                    d: 255
                }
            };
        }
        return {
            r: {
                h: '00',
                d: 0
            },
            g: {
                h: '00',
                d: 0
            },
            b: {
                h: '00',
                d: 0
            },
            a: {
                h: '00',
                d: 0
            }
        };

    }



    export default{
        name: 'prop-control',
        data: function () {
            return {
                selection: {
                    ex: false,
                    size: 0, x: 0, y: 0,
                    rotate: 0, fill_color: '',
                    stroke_color: '', fill_alpha: '',
                    stroke_alpha: ''
                },
                project: {
                    size: 0, fill_color: '', fill_alpha: 0,
                    stroke_color: '', stroke_alpha: 255,
                    overtone_color: '', overtone_alpha: 0
                }
            };
        },
        methods: {
            onwheels: function (event, key) {
                var sel = central_data().selection;
                if (sel) {
                    var input = event.target;
                    var delta = Math.sign(event.deltaY);
                    var value = U.FloatOr(input.value, 0);
                    switch (key) {
                        case 'size':
                            value += delta * .1;
                            central_data().set_selection_key('size', Math.max(1, value), null);
                            break;
                        case 'x':
                            value += delta * .1;
                            central_data().set_selection_key('x', Math.max(1, value), null);
                            break;
                        case 'y':
                            value += delta * .1;
                            central_data().set_selection_key('y', Math.max(1, value), null);
                            break;
                        case 'rotate':
                            value += delta * .1;
                            central_data().set_selection_key('rotate', value, null);
                            break;
                        case 'fill':
                            value += delta;
                            central_data().set_selection_key(key, this.$data.selection.fill_color, Math.min(Math.max(0, value), 225));
                            break;
                        case 'stroke':
                            value += delta;
                            central_data().set_selection_key(key, this.$data.selection.stroke_color, Math.min(Math.max(0, value), 225));
                            break;
                    }
                }
            },
            onwheelp: function (event, key) {
                var project = central_data().project;
                if (project) {
                    var input = event.target;
                    var delta = Math.sign(event.deltaY);
                    var value = U.FloatOr(input.value, 0);
                    switch (key) {
                        case 'size':
                            value += delta * .1;
                            central_data().set_project_key('size', Math.max(1, value), null);
                            break;
                        case 'fill_alpha':
                            value += delta;
                            central_data().set_project_key('fill', this.$data.project.fill_color, Math.min(Math.max(0, value), 225));
                            break;
                        case 'stroke_alpha':
                            value += delta;
                            central_data().set_project_key('stroke', this.$data.project.stroke_color, Math.min(Math.max(0, value), 225));
                            break;
                        case 'overtone_alpha':
                            value += delta;
                            central_data().set_project_key('overtone', this.$data.project.overtone_color, Math.min(Math.max(0, value), 225));
                            break;
                    }
                }
            },
            update_project: function (event, key) {
                var project = central_data().project;
                if (project) {
                    var input = event.target;
                    switch (key) {
                        case 'size':
                            central_data().set_project_key(key, input.value, null);
                            break;
                        case 'fill':
                            central_data().set_project_key(key, this.$data.project.fill_color, this.$data.project.fill_alpha);
                            break;
                        case 'stroke':
                            central_data().set_project_key(key, this.$data.project.stroke_color, this.$data.project.stroke_alpha);
                            break;
                        case 'overtone':
                            central_data().set_project_key(key, this.$data.project.overtone_color, this.$data.project.overtone_alpha);
                            break;
                    }
                }
            },
            update_selection: function (event, key) {
                var sel = central_data().selection;
                if (sel) {
                    var input = event.target;
                    switch (key) {
                        case 'size':
                        case 'x':
                        case 'y':
                        case 'rotate':
                            central_data().set_selection_key(key, input.value, null);
                            break;
                        case 'fill':
                            central_data().set_selection_key(key, this.$data.selection.fill_color, this.$data.selection.fill_alpha);
                            break;
                        case 'stroke':
                            central_data().set_selection_key(key, this.$data.selection.stroke_color, this.$data.selection.stroke_alpha);
                            break;
                    }
                }
            },
            update_view_p: function () {
                var pj = central_data().project;
                if (pj) {
                    var sz = U.FloatMoreOr(pj.header.size, 0, null);
                    this.$data.project.size = sz === null ? '' : sz.toFixed(6);
                    var fill_color = parse_color(pj.header.fill);
                    this.$data.project.fill_color = ['#', fill_color.r.h, fill_color.g.h, fill_color.b.h].join('');
                    this.$data.project.fill_alpha = fill_color.a.d;
                    var stroke_color = parse_color(pj.header.stroke);
                    this.$data.project.stroke_color = ['#', stroke_color.r.h, stroke_color.g.h, stroke_color.b.h].join('');
                    this.$data.project.stroke_alpha = stroke_color.a.d;
                    var overtone = parse_color(pj.header.overtone);
                    this.$data.project.overtone_color = ['#', overtone.r.h, overtone.g.h, overtone.b.h].join('');
                    this.$data.project.overtone_alpha = overtone.a.d;
                }
            },
            update_view: function () {
                var sel = central_data().selection;
                if (sel) {
                    this.$data.selection.size = sel.size.toFixed(6);
                    this.$data.selection.x = sel.x.toFixed(6);
                    this.$data.selection.y = sel.y.toFixed(6);
                    this.$data.selection.rotate = sel.rotate.toFixed(5);
                    var fill_color = parse_color(sel.fill);
                    this.$data.selection.fill_color = ['#', fill_color.r.h, fill_color.g.h, fill_color.b.h].join('');
                    this.$data.selection.fill_alpha = fill_color.a.d;
                    var stroke_color = parse_color(sel.stroke);
                    this.$data.selection.stroke_color = ['#', stroke_color.r.h, stroke_color.g.h, stroke_color.b.h].join('');
                    this.$data.selection.stroke_alpha = stroke_color.a.d;
                    this.$data.selection.ex = true;
                } else {
                    this.$data.selection.ex = false;
                }
            }
        },
        created: function () {
            central_data().on(events._SELECTION_CHANGED, this, this.update_view);
            central_data().on(events._SELECTION_DID_UPDATE, this, this.update_view);
            central_data().on(events._SELECTION_DID_ROTATE, this, this.update_view);
            central_data().on(events._PROJECT_CHANGED, this, this.update_view_p);
            central_data().on(events._PROJECT_LOAD_COMPLETE, this, this.update_view_p);
            central_data().on(events._PROJECT_PROP_CHANGED, this, this.update_view_p);
        }
    }
</script>
