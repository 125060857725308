<template>
    <div class="project-list">
        <div class="project-list-content">            
            <div class="project-list-body">
                <template v-if="error">
                    <div class="error-element">
                        <div class="error-element-inside">
                            <div class="error-element-text" v-text="error_message"></div>
                        </div>
                    </div>                    
                </template>
                <template v-if="!error">
                    <template v-if="!loaded"></template>
                    <template v-if="loaded">
                        <template v-if="!items.length">
                            <div class="info-element">
                                <div class="info-element-inside">
                                    <div class="info-element-text">У Вас пока нет ни одного проекта</div>
                                </div>
                            </div>
                        </template>
                        <template v-if="items.length">
                            <div class="project-list-list">
                                <template v-for="(item,index) in items" :key="index">
                                    <div class="project-list-element-container" @click="go_project(item.id)">
                                        <div class="project-list-element">
                                            <div class="project-list-element-image">
                                                <svg viewBox="0 0 1 1"></svg>
                                                <div class="project-list-element-image-container">
                                                    <img v-bind:src="item.image_url+'.SW300H300CF!default.jpg?_'+item.harsh" @error="get_default_image($event.target)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="project-text-element">
                                            <div class="project-text-content" v-text="item.name"></div>
                                        </div>
                                        <div class="project-control-element">
                                            <div class="project-control-button project-cintrol-button-edit" @click.prevent.stop="edit_project(item.id)" v-html="get_icon_edit()"></div>
                                            <div class="project-control-button project-cintrol-button-remove" @click.prevent.stop="delete_project(item.id)" v-html="get_icon_remove()"></div>
                                        </div>
                                    </div>

                                </template>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                                <div class="project-list-element-container-fake"></div>
                            </div>
                        </template>
                    </template>
                </template>
            </div>
        </div>
        <preloader ref="loader"/>
    </div>
</template>
<style lang="scss">
    .project-list {
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        .project-list-content {
            box-sizing: border-box;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            .project-list-panel {
                box-sizing: border-box;
                height: 2em;
                max-height: 2em;
                min-height: 2em;
                line-height: 2em;
                padding: 0 1em;
                .project-list-panel-content {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    h3 {
                        margin: 0;
                        font-size: 1.1em;
                        font-weight: bold;
                    }

                    .project-list-button-add {
                        box-sizing: border-box;
                        width: 2em;
                        height: 2em;
                        margin-left: 2em;
                        cursor: pointer;
                        line-height: 0;
                        padding: .25em;
                    }
                }
            }


            .project-list-body {
                box-sizing: border-box;
                padding: 1em;
                height: 100%;
                .error-element {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 1em;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .error-element-inside {
                        background: crimson;
                        color: white;
                        padding: 2em;
                        border-radius: 5px;
                    }
                }
                .info-element {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 1em;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .info-element-inside {
                        background: yellow;
                        border: 1px solid silver;
                        padding: 2em;
                        color: dimgray;
                        border-radius: 5px;
                    }
                }
                .project-list-list {
                    box-sizing: border-box;
                    overflow: auto;
                    height: 100%;
                    padding: 1em;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    .project-list-element-container-fake{
                        height:0;
                        padding:0;
                        width:15%;
                        max-width:15%;
                    }
                    .project-list-element-container {
                        width: 15%;
                        max-width: 15%;
                        min-width: 15%;
                        overflow: hidden;
                        border: 1px solid silver;
                        padding: 1px;
                        cursor: pointer;
                        position: relative;
                        .project-list-element {
                            box-sizing: border-box;
                            width: 100%;
                            overflow: hidden;
                            .project-list-element-image {
                                box-sizing: border-box;
                                width: 100%;
                                position: relative;
                                svg {
                                    width: 100%;
                                }
                                .project-list-element-image-container {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    overflow: hidden;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                            }
                        }
                        .project-control-element {
                            position: absolute;
                            top: 0;
                            right: -10em;
                            background: white;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            transition: right .3s;
                            border: 1px solid silver;
                            border-top-width: 0;
                            border-right-width: 0;
                            .project-control-button {
                                box-sizing: border-box;
                                width: 2.5em;
                                height: 2.5em;
                                line-height: 0;
                                margin: .1em .5em;
                                padding: .5em;
                                &.project-cintrol-button-edit svg {
                                    fill: blue;
                                }
                                &.project-cintrol-button-remove svg {
                                    fill: crimson;
                                }
                            }
                        }
                        &:hover .project-control-element {
                            right: 0;
                        }
                        .project-text-content {
                            box-sizing: border-box;
                            padding: .25em;
                            height: 2.5em;
                            overflow: hidden;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            line-height:1.25em;
                        }
                    }
                }
            }

        }
    }

</style>
<script>
    import preloader from '@/lib/vendor/eve/components/local-preloader.vue';
    import axios from 'axios';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    import U from '@/lib/vendor/eve/lib-utils';
    import router from '@/router/index';
    import icon_edit from '!!raw-loader!@/svg/common/edit.svg';
    import icon_remove from '!!raw-loader!@/svg/common/cross.svg';
    import projectHeader from '@/lib/vendor/eve/planhub/project/project-header';

    export default{
        name: 'project-list',
        data: function () {
            return {
                items: [],
                loader: true,
                loaded: false,
                error: false,
                error_message: ''
            };
        },
        components: {
            preloader: preloader
        },
        methods: {
            showLoader: function () {
                this.$refs.loader.show();
            },
            hideLoader: function () {
                this.$refs.loader.hide();
            },
            load: function () {
                this.$refs.loader.show();
                axios.get(central_data().mk_api_url({action: 'list_projects'}))
                        .then(this.on_list_response.bindTo(this))
                        .catch(this.on_global_error.bindTo(this))
                        .then(this.hideLoader.bindTo(this));
            },
            on_list_response: function (response) {
                if (response) {
                    if (U.isObject(response.data)) {
                        if (response.data.status === 'ok') {
                            return this.on_load_success(response.data.items);
                        }
                        if (response.data.status === 'error') {
                            return this.on_global_error(response.data.error_info.message);
                        }
                    }
                    return this.on_global_error('invalid server response');
                }
            },
            on_load_success: function (items) {
                var projects = [];
                var sa = U.safeArray(items);
                for (var i = 0; i < sa.length; i++) {
                    var project = projectHeader(sa[i]);
                    if (project && project.isValid()) {
                        projects.push(project);
                    }
                }
                this.$data.items = projects;
                this.$data.error = false;
                this.$data.loaded = true;
            },
            on_global_error: function (x) {
                if (x instanceof Error) {
                    x = x.message;
                }
                this.$data.error_message = U.NEString(x, 'unknown error');
                this.$data.error = true;
            },
            create_new: function () {
                router.push('/edit-project');
            },
            edit_project: function (id) {
                router.push('/edit-project/' + id);
            },
            delete_project: function (id) {
                this.showLoader();
                axios.get(central_data().mk_api_url({action: 'project_remove', remove_id: id}))
                        .then(this.on_list_response.bindTo(this))
                        .catch(this.on_global_error.bindTo(this))
                        .then(this.hideLoader.bindTo(this));
            },
//            get_image_url: function (item) {
//
//                return ['//', central_data().frontend_params.server,
//                    '/media/project/',
//                    item.id, '/project.SW300.jpg?_=', item.harsh
//                ].join('');
//
//            },
            get_default_image_url: function () {
                return require('!!raw-loader!./templates/common/default-image.txt').default;
            },
            get_default_image: function (img) {
                img.src = this.get_default_image_url();
            },
            get_icon_edit: function () {
                return icon_edit;
            },
            get_icon_remove: function () {
                return icon_remove;
            },
            go_project: function (id) {
                router.push('/project/' + id);
            }
        },
        mounted: function () {
            central_data().set_ui_state('APP-HEADER', {
                title: "Ваши проекты",
                buttons: [
                    {
                        hint: "Добавить новый проект",
                        message: 'project-list-add',
                        icon_html: require('!!raw-loader!@/svg/common/plus.svg').default
                    }
                ]
            });
            this.load();
        },
        created: function () {
            central_data().lem.clear_event('project-list-add');
            central_data().lem.on('project-list-add', this, this.create_new);
        }
    }
</script>
