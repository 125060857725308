<template>
    <div class="image-loader-content">
        <div class="image-loader-body">
            <div class="image-loader-image">
                <img  v-bind:src="get_image_url()" :key="c"/>
            </div>
            <input type="file" accept="image/*" @change="on_image" />
        </div>
    </div>
</template>
<style lang="scss">
    .image-loader-content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 1px solid silver;
        padding: 1px;
        .image-loader-body {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            line-height: 0;
            position: relative;
            .image-loader-image {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            input[type=file] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: red;
                cursor: pointer;
                opacity: 0;
            }

        }
    }
</style>
<script>
    import U from '@/lib/vendor/eve/lib-utils';
    import {central_data} from '@/lib/vendor/eve/planhub/lib-central-data';
    export default{
        name: 'image-loader',
        data: function () {
            return {
                c: 0,
                image: null,
                dataUrl: null,
                file: null,
                harsh: ''
            };
        },
        methods: {
            set_image: function (i, harsh) {
                this.$data.dataUrl = null;
                this.$data.file = null;
                this.$data.image = U.isObject(i) ? i : null;
                this.$data.harsh = harsh;
                this.$data.c += 1;
            },
            on_image: function (event) {
                var f = event.target.files && event.target.files.length ? event.target.files[0] : null;
                if (f) {
                    var reader = new FileReader();
                    var self = this;
                    reader.onloadend = function () {
                        self.$data.dataUrl = reader.result;
                    };
                    reader.readAsDataURL(f);
                    this.$data.file = f;
                }
            },
            get_operation: function () {
                if (this.$data.file) {
                    return 'formdata';
                }
                return 'none';
            },
            get_image: function () {
                if (this.$data.file) {
                    return this.$data.file;
                }
                return null;
            },
            get_image_url: function () {
                if (this.dataUrl) {
                    return this.dataUrl;
                }
                if (U.isObject(this.$data.image)) {
                    return ['//', central_data().frontend_params.server, '/media/', this.$data.image.context_name, '/', this.$data.image.owner_id, '/', this.$data.image.image_id, '.S!source.', this.$data.image.extension, '?_=', this.$data.harsh].join('');
                }
                return require('!!raw-loader!./templates/common/default-image.txt').default;
            }
        }
    }
</script>
