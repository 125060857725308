import U from './../lib-utils';
import Filter from './../lib-filter';
import {central_data} from './lib-central-data';
//import axios from 'axios';
//import events from './lib-events';
import primitiveCache from './project/primitiveCache';
function tool() {
    return (tool.is(this) ? this.init : tool.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(tool).prototype;

P.id = null;
P.name = null;
P.harsh = null;
P.image_url = null;
P.svg = null;

var rules = {
    'id': "Int:more=0,DefaultNull",
    'name': "String:trim=1;strip=1,NEString,DefaultNull",
    'harsh': "String:trim=1;strip=1,NEString,DefaultString:default=none"
};

P.init = function (o) {
    var filter = Filter();
    var clean = filter.apply_hash(U.safeObject(o), rules);
    filter.throw_bad_value(clean);
    for (var k in clean) {
        if (Object.prototype.hasOwnProperty.call(clean, k) && !U.is_callable(clean[k])) {
            if (Object.prototype.hasOwnProperty.call(P, k) && this[k] === null) {
                this[k] = clean[k];
            }
        }
    }
    if (this.isValid()) {
        var base_url = ['//', central_data().frontend_params.server, '/media/primitive/', this.id, '/primitive'].join('');
        this.image_url = [base_url, '.SW48H48CF!square.svg?_=', this.harsh].join('');
        if(!primitiveCache().has(this)){
            primitiveCache().load(this);
        }        
    }
};


P.is_valid = function () {
    return !!(this.id && this.name);
};

P.isValid = P.is_valid;

export default tool;