import U from './../lib-utils';


/**
 * 
 * @param {Number} imageWidth
 * @param {Number} imageHeight
 * @returns {layer_calculator|layer_calculator}
 */
// eslint-disable-next-line
function layer_calculator(imageWidth, imageHeight) {
    return (layer_calculator.is(this) ? this.init : layer_calculator.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(layer_calculator).prototype;

P.imageWidth = null;
P.imageHeight = null;
P.image1pcx = null;
P.image1pcy = null;

/**
 * 
 * @param {Number} imageWidth
 * @param {Number} imageHeight
 * @returns {layer_calculator}
 */
P.init = function (imageWidth, imageHeight) {
    this.imageWidth = U.FloatMoreOr(imageWidth, 0, null);
    this.imageHeight = U.FloatMoreOr(imageHeight, 0, null);
    if (this.imageHeight !== null) {
        this.image1pcy = this.imageHeight / 100;
    } else {
        this.image1pcy = null;
    }
    if (this.imageWidth !== null) {
        this.image1pcx = this.imageWidth / 100;
    } else {
        this.image1pcx = null;
    }
    return this;
};

/**
 * 
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object|null}
 */
P.calculate = function (viewportWidth, viewportHeight, scale, center_x, center_y) {
    if (this.imageWidth !== null && this.imageHeight !== null) {
        viewportWidth = U.FloatMoreOr(viewportWidth, 0, null);
        if (viewportWidth !== null) {
            viewportHeight = U.FloatMoreOr(viewportHeight, 0, null);
            if (viewportHeight !== null) {
                scale = U.FloatMoreOr(scale, 0.0, 1.0);
                center_x = U.FloatOr(center_x, 50); //center position in image percent coordinates
                center_y = U.FloatOr(center_y, 50);// center position in image percent coordinates
                // определяем область захвата - что попадает во вьюпорт:
                // это может быть как все изображение + немного полей, так и часть изображения
                // проще говоря это обратно масштабированный размер канваса (тоесть если масштаб = 0.5, то область захвата получается в 2 раза больше (х2))
                var cap_width = viewportWidth / scale;
                var cap_height = viewportHeight / scale;
                // позиция центральной точки в пикселах изображения    
                var image_center_point_px = this.image1pcx * center_x;
                var image_center_point_py = this.image1pcy * center_y;
                // вычисляем смещение стартовой точки от начала координат области захвата
                // оно зависит от масштаба
                var cap_start_x = image_center_point_px - (cap_width / 2);
                var cap_start_y = image_center_point_py - (cap_height / 2);
                // если старт захвата получился отрицательным - переводим его в смещение на канвасе
                var draw_offset_x = Math.abs(Math.min(0, cap_start_x)) * scale;
                var draw_offset_y = Math.abs(Math.min(0, cap_start_y)) * scale;
                // смещение на изображеннии - всегда больше либо равно 0
                var image_offset_x = Math.max(0, cap_start_x);
                var image_offset_y = Math.max(0, cap_start_y);
                return  {
                    viewport: {
                        w: viewportWidth,
                        h: viewportHeight
                    },
                    scale: scale,
                    center: {
                        pcu: {
                            x: center_x,
                            y: center_y
                        },
                        px: {
                            x: image_center_point_px,
                            y: image_center_point_py
                        }
                    },
                    cap: {
                        w: cap_width,
                        h: cap_height,
                        x: cap_start_x,
                        y: cap_start_y
                    },
                    draw: {
                        x: draw_offset_x,
                        y: draw_offset_y
                    },
                    image: {
                        offset_x: image_offset_x,
                        offset_y: image_offset_y,
                        w: this.imageWidth,
                        h: this.imageHeight,
                        pc1x: this.image1pcx,
                        pc1y: this.image1pcy
                    }
                };
            }
        }
    }
    return null;
};


/**
 * расчитывает координаты в изображении по px вьюпорта
 * @param {Number} x
 * @param {Number} y
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object}
 */
function calculate_image_from_viewport_px(x, y, viewportWidth, viewportHeight, scale, center_x, center_y) {
    scale = U.FloatMoreOr(scale, 0.0, 1.0);
    center_x = U.FloatOr(center_x, 50); //center position in image percent coordinates
    center_y = U.FloatOr(center_y, 50);// center position in image percent coordinates
    // определяем область захвата - что попадает во вьюпорт:
    // это может быть как все изображение + немного полей, так и часть изображения
    // проще говоря это обратно масштабированный размер канваса (тоесть если масштаб = 0.5, то область захвата получается в 2 раза больше (х2))
    var cap_width = viewportWidth / scale;
    var cap_height = viewportHeight / scale;
    // позиция центральной точки в пикселах изображения    
    var image_center_point_px = this.image1pcx * center_x;
    var image_center_point_py = this.image1pcy * center_y;
    // вычисляем смещение стартовой точки от начала координат области захвата
    // оно зависит от масштаба
    var cap_start_x = image_center_point_px - (cap_width / 2);
    var cap_start_y = image_center_point_py - (cap_height / 2);
    // если старт захвата получился отрицательным - переводим его в смещение на канвасе
    var draw_offset_x = Math.abs(Math.min(0, cap_start_x)) * scale;
    var draw_offset_y = Math.abs(Math.min(0, cap_start_y)) * scale;
    // смещение на изображеннии - всегда больше либо равно 0
    var image_offset_x = Math.max(0, cap_start_x);
    var image_offset_y = Math.max(0, cap_start_y);
    var _image_offset_x = draw_offset_x + (-1 * scale * image_offset_x);
    var _image_offset_y = draw_offset_y + (-1 * scale * image_offset_y);
    // это хотпойнт - оффсет
    var hot_point_image_x = x - _image_offset_x;
    var hot_point_image_y = y - _image_offset_y;
    // теперь переводим пиксели канваса в пиксели изображения:
    var hot_point_image_px_x = hot_point_image_x / scale;//Math.round(hot_point_image_x / scale);
    var hot_point_image_px_y = hot_point_image_y / scale;//Math.round(hot_point_image_y / scale);
    // теперь переводим пиксели изображения в проценты изображения:
    var hot_point_img_pc_x = (hot_point_image_px_x / this.image1pcx);
    var hot_point_img_pc_y = (hot_point_image_px_y / this.image1pcy);
    return {
        px: {
            x: hot_point_image_px_x,
            y: hot_point_image_px_y
        },
        pcu: {
            x: hot_point_img_pc_x,
            y: hot_point_img_pc_y
        }
    };
}

/**
 * Транслирует пиксельную точку вьюпорта в иные системы координат
 * @param {Number} x
 * @param {Number} y
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object}
 */
P.translate_viewport_px = function (x, y, viewportWidth, viewportHeight, scale, center_x, center_y) {
    var vpPxx = viewportWidth / 100;
    var vpPxy = viewportHeight / 100;
    var viewport_px = {x: x, y: y};
    var viewport_pcu = {x: x / vpPxx, y: y / vpPxy};
    return {
        viewport: {
            px: viewport_px,
            pcu: viewport_pcu
        },
        image: calculate_image_from_viewport_px.apply(this, [viewport_px.x, viewport_px.y, viewportWidth, viewportHeight, scale, center_x, center_y])
    };
};



/**
 * Транслирует pcu точку вьюпорта в иные системы координат
 * @param {Number} x
 * @param {Number} y
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object}
 */
P.translate_viewport_pcu = function (x, y, viewportWidth, viewportHeight, scale, center_x, center_y) {
    var vpPxx = viewportWidth / 100;
    var vpPxy = viewportHeight / 100;
    var viewport_px = {x: x * vpPxx, y: y * vpPxy};
    var viewport_pcu = {x: x, y: y};
    return {
        viewport: {
            px: viewport_px,
            pcu: viewport_pcu
        },
        image: calculate_image_from_viewport_px.apply(this, [viewport_px.x, viewport_px.y, viewportWidth, viewportHeight, scale, center_x, center_y])
    };
};

//<editor-fold defaultstate="collapsed" desc="image-to-viewport">

/**
 * транслирует пикселы изображения в иные системы координат
 * @param {Number} x
 * @param {Number} y
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object}
 */
P.translate_image_px = function (x, y, viewportWidth, viewportHeight, scale, center_x, center_y) {
    var image_px = {
        x: x, y: y
    };
    var image_pcu = {
        x: x / this.image1pcx,
        y: y / this.image1pcy
    };

    return {
        image: {
            px: image_px, pcu: image_pcu
        },
        viewport: calculate_viewport_from_image_pcu.apply(this, [image_pcu.x, image_pcu.y, viewportWidth, viewportHeight, scale, center_x, center_y])
    };
};

/**
 * 
 * @param {Number} x
 * @param {Number} y
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object}
 */
function calculate_viewport_from_image_pcu(x, y, viewportWidth, viewportHeight, scale, center_x, center_y) {
    scale = U.FloatMoreOr(scale, 0.0, 1.0);
    center_x = U.FloatOr(center_x, 50); //center position in image percent coordinates
    center_y = U.FloatOr(center_y, 50);// center position in image percent coordinates
    x = U.FloatOr(x, 0);
    y = U.FloatOr(y, 0);
    var cap_width = viewportWidth / scale;
    var cap_height = viewportHeight / scale;
    // позиция центральной точки в пикселах изображения    
    var image_center_point_px = this.image1pcx * center_x;
    var image_center_point_py = this.image1pcy * center_y;
    // вычисляем смещение стартовой точки от начала координат области захвата
    // оно зависит от масштаба
    var cap_start_x = image_center_point_px - (cap_width / 2);
    var cap_start_y = image_center_point_py - (cap_height / 2);
    // если старт захвата получился отрицательным - переводим его в смещение на канвасе
    var draw_offset_x = Math.abs(Math.min(0, cap_start_x)) * scale;
    var draw_offset_y = Math.abs(Math.min(0, cap_start_y)) * scale;
    // смещение на изображеннии - всегда больше либо равно 0
    var image_offset_x = Math.max(0, cap_start_x);
    var image_offset_y = Math.max(0, cap_start_y);


    // вычисляем оффсет изображения от канвы
    // он либо draw_offset_x (смещение от начала канвы до начала изображения)
    // либо image_offset_y (смещение от начала изображения до начала области захвата)
    // либо 0 если начало изображения совпадает с началом канваса
    // в координатах image
    var _image_offset_x = (draw_offset_x / scale) + (-1 * image_offset_x);
    var _image_offset_y = (draw_offset_y / scale) + (-1 * image_offset_y);
    // переводим image pcu в image px
    //!!!ПОДУМАТЬ
    var hot_point_x_image_px = x * this.image1pcx;
    var hot_point_y_image_px = y * this.image1pcy;
    // координаты  в пикселях канваса:
    // это хотпойнт + оффсет
    var hot_point_image_x = hot_point_x_image_px + _image_offset_x;
    var hot_point_image_y = hot_point_y_image_px + _image_offset_y;
    // теперь переводим пиксели изображения в пиксели канваса:
    var hot_point_image_px_x = hot_point_image_x * scale;
    var hot_point_image_px_y = hot_point_image_y * scale;
    // теперь переводим пиксели канваса в pcu канваса:
    var hot_point_img_pc_x = hot_point_image_px_x / (viewportWidth / 100);
    var hot_point_img_pc_y = hot_point_image_px_y / (viewportHeight / 100);
    return {
        px: {
            x: hot_point_image_px_x,
            y: hot_point_image_px_y
        },
        pcu: {
            x: hot_point_img_pc_x,
            y: hot_point_img_pc_y
        }
    };
}

/**
 * 
 * @param {Number} x
 * @param {Number} y
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @param {Number} scale
 * @param {Number} center_x
 * @param {Number} center_y
 * @returns {Object}
 */
P.translate_image_pcu = function (x, y, viewportWidth, viewportHeight, scale, center_x, center_y) {
    var image_px = {
        x: x * this.image1pcx, y: y * this.image1pcy
    };
    var image_pcu = {
        x: x,
        y: y
    };

    return {
        image: {
            px: image_px, pcu: image_pcu
        },
        viewport: calculate_viewport_from_image_pcu.apply(this, [image_pcu.x, image_pcu.y, viewportWidth, viewportHeight, scale, center_x, center_y])
    };
};
//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="fits">
/**
 * 
 * @param {Number} viewportWidth
 * @returns {Number}
 */
P.calculate_fit_width = function (viewportWidth) {
    return U.FloatMoreOr(viewportWidth, 0, 0) / this.imageWidth;
};


/**
 * 
 * @param {Number} viewportHeight
 * @returns {Number}
 */
P.calculate_fit_height = function (viewportHeight) {
    return U.FloatMoreOr(viewportHeight, 0, 0) / this.imageHeight;
};
/**
 * 
 * @param {Number} viewportWidth
 * @param {Number} viewportHeight
 * @returns {Number}
 */

P.calculate_fit = function (viewportWidth, viewportHeight) {
    return Math.min(this.calculate_fit_width(viewportWidth), this.calculate_fit_height(viewportHeight));
};
//</editor-fold>



/**
 * Относительная трансляция - не учитывает оффсет изображения
 * применяется для мов-процессинга
 * @param {Number} xPx
 * @param {Number} yPx
 * @param {Number} scale
 * @returns {Object}
 */
P.translate_viewport_px_to_image_pcu = function (xPx, yPx, scale) {
    // вычисляем оффсет в пикселах изображения
    var ixPx = xPx / scale;
    var iyPx = yPx / scale;
    var ixPcu = ixPx / this.image1pcx;
    var iyPcu = iyPx / this.image1pcy;
    return {
        x: ixPcu,
        y: iyPcu
    };
};

P.extend_event_with_image_pcu = function (evt, viewportWidth, viewportHeight, scale, center_x, center_y, whKey) {
    whKey = U.NEString(whKey, 'offset');
    var x = event[[whKey, 'X'].join('')];
    var y = event[[whKey, 'Y'].join('')];
    evt.calculated = this.translate_viewport_px(x, y, viewportWidth, viewportHeight, scale, center_x, center_y);
};
P.extend_touch_with_image_pcu = function (touch, viewportWidth, viewportHeight, scale, center_x, center_y, rect) {
    var x = touch.cx - rect.left;
    var y = touch.cy - rect.top;
    touch.calculated = this.translate_viewport_px(x, y, viewportWidth, viewportHeight, scale, center_x, center_y);
};

export default layer_calculator;