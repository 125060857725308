import U from './lib-utils';
import template from '!!raw-loader!./templates/lib-alert/template.html';
import alert_template from '!!raw-loader!./templates/lib-alert/alert.html';
import css_style from '!!raw-loader!./templates/lib-alert/style.css';
import css_style_size from '!!raw-loader!./templates/lib-alert/style.size.css';
import mustache from '@/lib/vendor/mustache/mustache';
import {callback} from './lib-callback-manager';
var icons = {};
var files = require.context('!file-loader!./templates/lib-alert/icons', false, /\.svg$/);
files.keys().forEach(function (key) {
    var m = /^.\/(.*)\.svg$/.exec(key);
    if (m) {
        icons[m[1].toLowerCase()] = require('!!raw-loader!./templates/lib-alert/icons/' + m[1] + '.svg').default;
    }
});
var styles_to_install = [];
var alert_styles = require.context('!!raw-loader!./templates/lib-alert/styles', false, /\.css$/);
alert_styles.keys().forEach(function (key) {
    var m = /^.\/(.*)\.css$/.exec(key);
    if (m) {
        styles_to_install.push(alert_styles(key).default);
    }
});
var H = null;
//<editor-fold defaultstate="collapsed" desc="alert manager">
function alert_manager() {
    return alert_manager.is(H) ? H : ((alert_manager.is(this) ? this.init : alert_manager.F).apply(this, Array.prototype.slice.call(arguments)));
}

var AMP = U.fixup_constructor(alert_manager).prototype;

var handle = null;
var style = null;
var size_style = null;
var RSTO = null;


function prepend_styles() {
    var headers = document.getElementsByTagName('head');
    var header_element = null;
    if (headers.length) {
        header_element = headers.item(0);
    } else {
        header_element = document.documentElement;
    }
    size_style = document.createElement('style');
    size_style.type = 'text/css';
    size_style.id = 'lib-alert-style-size';
    header_element.prepend(size_style);
    var theme_style = document.createElement('style');
    theme_style.type = 'text/css';
    theme_style.id = 'lib-alert-style-theme';
    theme_style.innerText = styles_to_install.join('\n');
    header_element.prepend(theme_style);
    style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'lib-alert-style';
    style.innerText = css_style;
    header_element.prepend(style);
}

function clear_to() {
    if (RSTO) {
        if (window.requestAnimationFrame) {
            window.cancelAnimationFrame(RSTO);
        } else {
            window.clearTimeout(RSTO);
        }
        RSTO = null;
    }
}

function render_style(mw, mh) {
    size_style.innerText = css_style_size.replace(/\$w\$/ig, mw).replace(/\$h\$/ig, mh);
}

function handle_resize() {
    clear_to();
    var ww = window.innerWidth;
    var wh = window.innerHeight;
    var max_width = parseInt(ww - ((ww / 100) * 20));
    var max_height = parseInt(wh - ((wh / 100) * 20));
    render_style(max_width, max_height);
}

function append() {
    var bodys = document.getElementsByTagName('body');
    if (bodys.length) {
        bodys.item(0).appendChild(handle);
    }
}

function create_handle() {
    var wrapper = document.createElement('div');
    wrapper.innerHTML = template;
    handle = wrapper.children[0];
    prepend_styles();
    append();
}

AMP.init = function () {
    H = this;
    create_handle();
    window.addEventListener('resize', function () {
        clear_to();
        if (window.requestAnimationFrame) {
            RSTO = window.requestAnimationFrame(handle_resize);
        } else {
            RSTO = window.setTimeout(handle_resize, 0);
        }
    });
    handle_resize();
    return this;
};

AMP.add = function (ali) {
    handle.appendChild(ali.handle);
};



function alert() {
    return (alert.is(this) ? this.init : alert.F).apply(this, Array.prototype.slice.call(arguments));
}

var AP = U.fixup_constructor(alert).prototype;

AP.options = null;
AP.handle = null;
AP._to = null;
AP.init = function (options) {
    this.options = this.get_default_options();
    var ops = U.safe_object(options);
    for (var k in ops) {
        if (Object.prototype.hasOwnProperty.call(ops,k)) {
            if (Object.prototype.hasOwnProperty.call(this.options,k)) {
                this.options[k] = ops[k];
            }
        }
    }
    this.set_message(this.options.text);
    this.set_callback(this.options.callback).set_command_processor(this.options.command_processor);
    if (U.any_bool(this.options.show, false)) {
        this.show();
    }

    return this;
};

AP.show = function () {
    this.init_handle();
    return this;
};

AP.set_title = function (title) {
    this.options.title = U.NEString(title, this.options.title);
    return this;
};

AP.set_text = function (text) {
    this.options.text = U.NEString(text, this.options.text).replace(/\n/g,'<br/>');
    return this;
};
AP.set_message = AP.set_text;
AP.set_close = function (show_close_button) {
    this.options.close = U.any_bool(show_close_button, false);
    return this;
};
AP.set_timeout = function (ms) {
    this.options.timeout = U.intMoreOr(ms, 0, -1);
    return this;
};

AP.set_style = function (st_name) {
    this.options.style = U.NEString(st_name, 'default');
    return this;
};

AP.set_icon = function (x) {
    if (!x) {
        this.options.icon = false;
    }
    var xv = U.NEString(x, null);
    this.options.icon = xv ? xv : false;
    return this;
};
AP.set_command_processor = function (ca) {
     if (callback.is(ca)) {
        this.options.command_processor = ca;
    } else if (U.is_callable(ca)) {
        this.options.command_processor = callback( ca);
    } else {
        this.options.command_processor = null;
    }
    return this;
};

AP.set_callback = function (ca) {
    if (callback.is(ca)) {
        this.options.callback = ca;
    } else if (U.is_callable(ca)) {
        this.options.callback = callback(ca);
    } else {
        this.options.callback = null;
    }
    return this;
};

AP.get_default_options = function () {
    return {
        close: false,
        title: 'title',
        text: 'text',
        timeout: 3000,
        style: 'default',
        callback: null,
        command_processor: null,
        show: false,
        icon: false
    };
};

function render_dom(obj){
    var div = document.createElement('div');
    div.innerHTML = mustache.render(alert_template,obj);
    return div.children[0];
}

AP.init_handle = function () {
    
    this.handle = render_dom(this);
    this.init_handlers();
    alert_manager().add(this);
    var _to = U.intMoreOr(this.options.timeout, -1, 0);
    if (_to > 0) {
        this._to = window.setTimeout(this.hide.bindTo(this), _to);
    }

};

function up_to_command(currentNode, stopNode) {
    if (currentNode && currentNode.dataset && currentNode.dataset.libAlertCommand && U.NEString(currentNode.dataset.libAlertCommand, null)) {
        return currentNode;
    }
    if (currentNode === stopNode) {
        return null;
    }
    if (currentNode.parentElement) {
        return up_to_command(currentNode.parentElement, stopNode);
    }
    return null;
}

function _click(e) {
    var command_node = up_to_command(e.target, this.handle);
    if (command_node) {
        var command = U.NEString(command_node.dataset.libAlertCommand, null);
        if (command) {
            var fn = ['_on_command_', command.toLowerCase()].join('');
            if (U.is_callable(this[fn])) {
                return this[fn]();
            }
            if (this.options.command_processor) {
                if (false !== this.options.command_processor.run(command, this)) {
                    return this;
                }
            }
        }
    }
    if (U.any_bool(this.options.close, false)) {
        return this;
    }
    return this.hide();
}

AP.init_handlers = function () {
    this.handle.addEventListener('click', _click.bindTo(this));
};

AP._on_command_close = function () {
    this.hide();
    return true;
};

AP.hide = function () {
    if (this._to) {
        window.clearTimeout(this._to);
        this._to = null;
    }
    this.handle.removeEventListener('click',_click.bindTo(this));
    if (window.requestAnimationFrame) {
        var start = performance.now();
        var self = this;
        var animation = function (time) {
            var timeFraction = (time - start) / 300;
            if (timeFraction > 1) {
                timeFraction = 1;
            }
            var opacity = Math.max(1.0 - (timeFraction * 2.0), 0);
            var scale = 0.8 + timeFraction * 3;
            self.handle.style.opacity = opacity.toFixed(6);
            self.handle.style.transform = ['scale(', scale.toFixed(6), ')'].join('');
            if (timeFraction < 1) {
                requestAnimationFrame(animation);
                return;
            }
            self.handle.remove();
            self.options.callback ? self.options.callback.run() : 0;

        };
        requestAnimationFrame(animation);
    } else {
        this.handle.remove();
        this.options.callback ? this.options.callback.run() : 0;
    }
};


AP.render_window_class = function () {
    var c = [];
    c.push(['lib-alert-window-style-', U.NEString(this.options.style, 'none')].join(''));
    if (U.any_bool(this.options.close, false)) {
        c.push('lib-alert-window-close-enabled');
    }
    if (this.options.icon && U.NEString(this.options.icon, null)) {
        c.push('lib-alert-window-icon-enabled');
    }
    return c.join(' ');
};

AP.render_title_text = function () {
    return U.NEString(this.options.title, '-title-');
};

AP.render_alert_content = function () {
    return U.NEString(this.options.text, '-text-');
};

AP.render_icon = function () {
    if (null === U.NEString(this.options.icon, null)) {
        return '';
    } else if (/^preset:/i.test(this.options.icon)) {
        return this.render_predefined_icon();
    } else if (/^#/i.test(this.options.icon)) {
        return ['<svg><use xlink:href="', this.options.icon, '"></svg>'].join('');
    } else if (/<svg/i.test(this.options.icon)) {
        return this.options.icon;
    } else if (U.NEString(this.options.icon, null)) {
        return ['<div class="lib-alert-window-icon-image"><img src="', this.options.icon, '"/></div>'].join('');
    }
    return '';
};

AP.render_predefined_icon = function () {
    var m = /^preset:(\S{1,})$/i.exec(this.options.icon);
    if (m) {
        var c = U.NEString(m[1].toLowerCase(), null);
        if (c) {
            if (Object.prototype.hasOwnProperty.call(icons,c)) {
                return icons[c];
            }
        }
    }
    return '';
};

export {alert};