/* eslint-disable */
import U from './../lib-utils';
import abstractLayer from './abstractLayer';
import layerLayout from './layerLayout';
import events from './lib-events';


/**
 * 
 * @param {type} layout
 * @returns {layer.F|layer.init}
 */
function layer(layout) {
    return (layer.is(this) ? this.init : layer.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = layer.leu_extend(abstractLayer).prototype;


P.handle = null;
P.tl = null;
P.tr = null;
P.br = null;
P.bl = null;
P.cn = null;


P.on_init = function (layout) {
    this.handle = document.createElement('div');
    this.handle.classList.add('lib-map-layer');
    this.handle.classList.add('lib-map-layer-selection-v-html');
    var handles = ['tl', 'tr', 'br', 'bl', 'cn', 'rm'];
    for (var i = 0; i < handles.length; i++) {
        this[handles[i]] = document.createElement('div');
        this[handles[i]].classList.add('lib-map-layer-selection-handle');
        this[handles[i]].classList.add('lib-map-layer-selection-handle-' + handles[i]);
        this[handles[i]].dataset.dir = handles[i];
        if (handles[i] === 'cn') {
            this[handles[i]].addEventListener('mousedown', this._rotate_mouse_down.bindTo(this));
        } else if (handles[i] !== 'rm') {
            this[handles[i]].addEventListener('mousedown', this._handle_mouse_down.bindTo(this));
        }
        this.handle.appendChild(this[handles[i]]);
    }
    this.rm.addEventListener('click', this._handle_removing.bindTo(this));
    this.rm.innerHTML = require('!!raw-loader!@/svg/common/cross.svg').default;
    this.layout.centralData.lem.on(events._SELECTION_CHANGED, this, this.draw);
    this.handle.addEventListener('mousedown', this._handle_drag_mousedown.bindTo(this));
    this.handle.addEventListener('wheel', this.on_wheel.bindTo(this));
    return this;
};

P._handle_removing = function () {
    this.layout.centralData.remove_selected();
};

P.on_wheel = function (event) {
    this.layout.on_wheel({deltaX: event.deltaX, deltaY: event.deltaY, offsetX: event.offsetX + this.handle.offsetLeft, offsetY: event.offsetY + this.handle.offsetTop});
};

//<editor-fold defaultstate="collapsed" desc="rotate">
P._rotate_mouse_down = function (event) {
    this._start = {
        x: event.pageX,
        y: event.pageY,
        t: U.FloatOr(this.handle.style.top, 0),
        l: U.FloatOr(this.handle.style.left),
        w: U.FloatOr(this.handle.style.width, 0),
        h: U.FloatOr(this.handle.style.height),
        r: U.FloatOr(this.layout.centralData.selection.rotate, 0)

                //нужен радиус-вектор от точки чентра до точки касания
    };
    // точка центра - по баундингу 
    var bounds_center = this.handle.getBoundingClientRect();
    var mark_center = this.cn.getBoundingClientRect();
    var mark_center_x = mark_center.x + mark_center.width / 2;
    var mark_center_y = mark_center.top + mark_center.height / 2;
    this._start.cx = bounds_center.x + bounds_center.width / 2;
    this._start.cy = bounds_center.y + bounds_center.height / 2; //  спользуем не позицию курсора для старта, а позицию центра rv
    this._start.sx = mark_center_x;
    this._start.sy = mark_center_y;
    var dx = this._start.sx - this._start.cx;
    var dy = this._start.sy - this._start.cy;
    this._start.rva = Math.atan2(dy, dx) * (180 / Math.PI) + 45;
    document.addEventListener('mousemove', this._rotate_wait.bindTo(this));
    document.addEventListener('mouseup', this._rotate_abort.bindTo(this));
};

P._rotate_abort = function () {
    document.removeEventListener('mousemove', this._rotate_wait.bindTo(this));
    document.removeEventListener('mouseup', this._rotate_abort.bindTo(this));
    document.removeEventListener('mousemove', this._while_rotate.bindTo(this));
    document.removeEventListener('mouseup', this._commit_rotate.bindTo(this));
};

P._rotate_wait = function (event) {
    var dx = event.pageX - this._start.x;
    var dy = event.pageY - this._start.y;
    var d = Math.sqrt(dx * dx + dy * dy);
    if (d > 5) {
        this._rotate_abort();
        document.addEventListener('mousemove', this._while_rotate.bindTo(this));
        document.addEventListener('mouseup', this._commit_rotate.bindTo(this));
        this._while_rotate(event);
    }
};

P._commit_rotate = function (event) {
    this._rotate_abort();
    this._while_rotate(event);
};

P._while_rotate = function (event) {
    // вычисляем текущий радиусвектор
    var dx = event.pageX - this._start.cx;
    var dy = event.pageY - this._start.cy;
    var rva = Math.atan2(dy, dx) * (180 / Math.PI) + 45;
    var current_angle = this._start.r + (rva - this._start.rva);
    var str = ['rotate(', U.FloatOr(current_angle, 0).toFixed(5), 'deg)'].join('');
    this.handle.style.transform = str;
    this.trigger_update_rotation(current_angle);

};
P.trigger_update_rotation = function (ca) {
    this.layout.centralData.selection.rotate = ca;
    this.layout.centralData.lem.run(events._SELECTION_DID_ROTATE, this.layout.centralData.selection);

};
//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="move">

P._handle_drag_mousedown = function (event) {
    if (event.target === this.handle) {
        this._start = {
            x: event.pageX,
            y: event.pageY,
            dir: event,
            t: U.FloatOr(this.handle.style.top, 0),
            l: U.FloatOr(this.handle.style.left),
            w: U.FloatOr(this.handle.style.width, 0),
            h: U.FloatOr(this.handle.style.height)
        };
        document.addEventListener('mouseup', this._handle_drag_abort.bindTo(this));
        document.addEventListener('mousemove', this._handle_drag_wait.bindTo(this));
    }
};

P._handle_drag_abort = function () {
    document.removeEventListener('mouseup', this._handle_drag_abort.bindTo(this));
    document.removeEventListener('mousemove', this._handle_drag_wait.bindTo(this));
    document.removeEventListener('mousemove', this._handle_while_drag.bindTo(this));
    document.removeEventListener('mouseup', this._handle_commit_drag.bindTo(this));
};

P._handle_drag_wait = function (event) {
    var dx = event.pageX - this._start.x;
    var dy = event.pageY - this._start.y;
    var d = Math.sqrt(dx * dx + dy * dy);
    if (d > 5) {
        this._handle_drag_abort();
        document.addEventListener('mousemove', this._handle_while_drag.bindTo(this));
        document.addEventListener('mouseup', this._handle_commit_drag.bindTo(this));
        this._handle_while_drag(event);
    }
};

P._handle_commit_drag = function (event) {
    this._handle_drag_abort();
    this._handle_while_drag(event);
};

P._handle_while_drag = function (event) {
    var dx = event.pageX - this._start.x;
    var dy = event.pageY - this._start.y;
    var nLeft = (this._start.l + dx);
    var nTop = (this._start.t + dy);
    this.handle.style.left = [nLeft.toFixed(3), 'px'].join('');
    this.handle.style.top = [nTop.toFixed(3), 'px'].join('');
    this.trigger_update(nLeft, nTop, this._start.w, this._start.h);
};
//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="resize">
P._handle_mouse_down = function (evt) {
    var node = evt.target;
    var dir = node.dataset.dir;
    this._start = {
        x: evt.pageX,
        y: evt.pageY,
        dir: dir,
        t: U.FloatOr(this.handle.style.top, 0),
        l: U.FloatOr(this.handle.style.left),
        w: U.FloatOr(this.handle.style.width, 0),
        h: U.FloatOr(this.handle.style.height)
    };
    document.addEventListener('mousemove', this._whiledrag.bindTo(this));
    document.addEventListener('mouseup', this._abortdrag.bindTo(this));
};

P._abortdrag = function () {
    document.removeEventListener('mousemove', this._waitdrag.bindTo(this));
    document.removeEventListener('mouseup', this._abortdrag.bindTo(this));
    document.removeEventListener('mousemove', this._whiledrag.bindTo(this));
    document.removeEventListener('mouseup', this._commitdrag.bindTo(this));
};

P._waitdrag = function (evt) {
    var dx = evt.pageX - this._start.x;
    var dy = evt.pageY - this._start.y;
    var d = Math.sqrt(dx * dx + dy * dy);
    if (d > 5) {
        this._abortdrag();
        document.addEventListener('mousemove', this._whiledrag.bindTo(this));
        document.addEventListener('mouseup', this._commitdrag.bindTo(this));
        this._whiledrag(evt);
    }
};

P.trigger_update = function (l, t, w, h) {
    var translated = this.layout.calculator.translate_viewport_px(l + w / 2, t + h / 2, this.layout.layout_width, this.layout.layout_height, this.layout.scale, this.layout.center_x, this.layout.center_y);
    var size = (w / this.layout.calculation_result.image.pc1x) / this.layout.scale;
    var x = translated.image.pcu.x;
    var y = translated.image.pcu.y;
    this.layout.centralData.selection.x = x;
    this.layout.centralData.selection.y = y;
    this.layout.centralData.selection.size = size;
    this.layout.centralData.lem.run(events._SELECTION_DID_UPDATE, this.layout.centralData.selection);

};

P._whiledrag = function (evt) {
    var dx = evt.pageX - this._start.x;
    var vector = dx;//* Math.cos(U.FloatOr(this.layout.centralData.selection.rotate, 0)); // абсолютное изменение - транслировать относительно центра?
    var nWidth = null, nHeight = null, nTop = null, nLeft = null;
    if (this._start.dir === 'br') {
        nWidth = (this._start.w + vector * 2);
        if (nWidth > 24) {
            nHeight = (this._start.h + vector * 2);
            nLeft = (this._start.l - vector);
            nTop = (this._start.t - vector);
            this.handle.style.width = [nWidth.toFixed(3), 'px'].join('');
            this.handle.style.height = [nHeight.toFixed(3), 'px'].join('');
            this.handle.style.left = [nLeft.toFixed(3), 'px'].join('');
            this.handle.style.top = [nTop.toFixed(3), 'px'].join('');
            this.trigger_update(nLeft, nTop, nWidth, nHeight);
        }
    } else if (this._start.dir === 'bl') {
        nWidth = (this._start.w - vector * 2);
        if (nWidth > 24) {
            nLeft = (this._start.l + vector);
            nTop = (this._start.t + vector);
            nHeight = (this._start.h - vector * 2);
            this.handle.style.left = [nLeft.toFixed(3), 'px'].join('');
            this.handle.style.top = [nTop.toFixed(3), 'px'].join('');
            this.handle.style.width = [nWidth.toFixed(3), 'px'].join('');
            this.handle.style.height = [nHeight.toFixed(3), 'px'].join('');
            this.trigger_update(nLeft, nTop, nWidth, nHeight);
        }
    } else if (this._start.dir === 'tl') {
        nWidth = (this._start.w - vector * 2);
        if (nWidth > 24) {
            nHeight = (this._start.h - vector * 2);
            nLeft = (this._start.l + vector);
            nTop = (this._start.t + vector);
            this.handle.style.left = [nLeft.toFixed(3), 'px'].join('');
            this.handle.style.top = [nTop.toFixed(3), 'px'].join('');
            this.handle.style.width = [nWidth.toFixed(3), 'px'].join('');
            this.handle.style.height = [nHeight.toFixed(3), 'px'].join('');
            this.trigger_update(nLeft, nTop, nWidth, nHeight);
        }
    } else if (this._start.dir === 'tr') {
        nWidth = (this._start.w + vector * 2);
        if (nWidth > 24) {
            nHeight = (this._start.h + vector * 2);
            nLeft = (this._start.l - vector);
            nTop = (this._start.t - vector);
            this.handle.style.width = [nWidth.toFixed(3), 'px'].join('');
            this.handle.style.height = [nHeight.toFixed(3), 'px'].join('');
            this.handle.style.left = [nLeft.toFixed(3), 'px'].join('');
            this.handle.style.top = [nTop.toFixed(3), 'px'].join('');
            this.trigger_update(nLeft, nTop, nWidth, nHeight);
        }
    }
};

P._commitdrag = function (evt) {
    this._abortdrag();
    this._whiledrag(evt);
    //fixup;
};

P.init_binders = function () {
    abstractLayer.prototype.init_binders.apply(this, Array.prototype.slice.call(arguments));
};

P.clear = function () {
    this.handle.style.display = 'none';
    return this;
};

P.resize = function () {
    return this;
};
P.on_data_ready = function () {
//
};

P.on_image_ready = function () {
    this.handle.style.display = 'none';
};


P.draw = function () {

    if (this.layout.centralData.selection) {
        var sel = this.layout.centralData.selection;
        var project = this.layout.centralData.project;
        var aspect = this.get_image_width() / this.get_image_height();
        var translated = this.layout.calculator.translate_image_pcu(sel.x, sel.y, this.layout.layout_width, this.layout.layout_height, this.layout.scale, this.layout.center_x, this.layout.center_y);//function (x, y, viewportWidth, viewportHeight, scale, center_x, center_y)         
        //console.log(translated, this.layout.calculation_result, aspect, project);
        var center_x = translated.viewport.px.x;
        var center_y = translated.viewport.px.y;
        var width = this.layout.calculation_result.image.pc1x * this.layout.scale * U.FloatMoreOr(sel.size, 0, U.FloatOr(project.size, 5));
        var height = width;//this.layout.calculation_result.image.pc1y * this.layout.scale * U.FloatMoreOr(sel.size, 0, U.FloatOr(project.size, 5));
        this.handle.style.left = [(center_x - width / 2).toFixed(3), 'px'].join('');
        this.handle.style.top = [(center_y - height / 2).toFixed(3), 'px'].join('');
        this.handle.style.width = [(width).toFixed(3), 'px'].join('');
        this.handle.style.height = [(height).toFixed(3), 'px'].join('');
        this.handle.style.transform = ['rotate(', U.FloatOr(sel.rotate, 0).toFixed(5), 'deg)'].join('');
       // window.calc = this.layout.calculator;
        this.handle.style.display = 'block';
    } else {
        this.handle.style.display = 'none';
    }

};


P.on_click_tap = function () {

    return false;
};

export default layer;