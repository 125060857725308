import U from './../../lib-utils';
import Filter from './../../lib-filter';

var rules = {
    id: 'Int:more=0,DefaultNull',
    layer_id: 'Int:more=0,DefaultNull',
    primitive_id: 'Int:more=0,DefaultNull',
    name: 'String:trim=1;strip=1,NEString,DefaultNull',
    size: 'Float:more=0,DefaultNull',
    x: 'Float,DefaultNull',
    y: 'Float,DefaultNull',
    stroke: 'String:trim=1;strip=1,NEString,DefaultString:default=,HTMLColor:default=#000000ff',
    fill: 'String:trim=1;strip=1,NEString,DefaultString:default=,HTMLColor:default=#ffffff00',
    rotate: 'Float,DefaultFloat:default=0.0'
};


function layerPoint() {
    return (layerPoint.is(this) ? this.init : layerPoint.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(layerPoint).prototype;


P.id = null;
P.layer_id = null;
P.primitive_id = null;
P.name = null;
P.size = null;
P.x = null;
P.y = null;
P.stroke = null;
P.fill = null;
P.rotate = null;
P.uid = null;

P.init = function (o) {
    var filter = Filter();
    var clean = filter.apply_hash(U.safeObject(o), rules);
    filter.throw_bad_value(clean);
    for (var k in clean) {
        if (Object.prototype.hasOwnProperty.call(clean, k) && !U.is_callable(clean[k])) {
            if (Object.prototype.hasOwnProperty.call(P, k) && this[k] === null) {
                this[k] = clean[k];
            }
        }
    }
    this.uid = U.get_uuid();
    return this;
};


P.is_valid = function () {
    return !!(this.id && this.layer_id && this.primitive_id && this.name && this.x !== null && this.y !== null);
};

P.isValid = P.is_valid;



export default layerPoint;