import U from './../lib-utils';
import layerLayout from './layerLayout';

function append_node_to_head(n) {
    var heads = document.getElementsByTagName('head');
    if (!heads || !heads.length) {
        heads = document.getElementsByTagName('body');
        if (!heads || !heads.length) {
            heads = [document.documentElement];
        }
    }
    if (heads && heads.length) {
        heads[0].appendChild(n);
    }
}

function prepend_node_to_head(n) {
    var heads = document.getElementsByTagName('head');
    if (!heads || !heads.length) {
        heads = document.getElementsByTagName('body');
        if (!heads || !heads.length) {
            heads = [document.documentElement];
        }
    }
    if (heads && heads.length) {
        if (heads[0].children.length) {
            heads[0].insertBefore(n, heads[0].children[0]);
        } else {
            heads[0].appendChild(n);
        }
    }
}




function abstract_layer() {
    throw new Error('-lib-map:abstract layer is abstract');
}

var P = U.fixup_constructor(abstract_layer).prototype;

P.layout = null;
P.handle = null;
P.instance_id = null;


P.init = function (layout) {
    if (!layerLayout.is(layout)) {
        throw new Error('-lib-map:abstractLayer designed to operate in layerLayout');
    }
    this.instance_id = ['layer', U.get_uuid().replace(/-/g, '')].join('');
    this.layout = layout;
    this.init_binders();
    this.on_init.apply(this, Array.prototype.slice.call(arguments));
    return this;
};

P.on_init = function () {

};

P.init_binders = function () {
    this.get_image_width = this._get_image_width.bind_to(this);
    this.get_image_height = this._get_image_height.bind_to(this);
    this.get_layout_width = this._get_layout_width.bind_to(this);
    this.get_layout_height = this._get_layout_height.bind_to(this);

};

P.on_data_ready = function () {
//
};

P.on_image_ready = function () {
//
};

P.on_hover = function () {
//
};

P.on_click_tap = function () {
//
    return false;
};


P.resize = function () {

};

P.clear = function () {

};

P.draw = function () {

};


/**
 * 
 * @param {HTMLElement} n
 * @returns {abstract_layer}
 */
P.prepend_node_to_head = function () {
    prepend_node_to_head.apply(this, Array.prototype.slice.call(arguments));
    return this;
};
/**
 * 
 * @param {HTMLElement} n
 * @returns {abstract_layer}
 */
P.append_node_to_head = function () {
    append_node_to_head.apply(this, Array.prototype.slice.call(arguments));
    return this;
};

/**
 * 
 * @returns {Number}
 */
P._get_image_width = function () {
    try {
        return this.get_background_image().width;
    } catch (e) {
        //console.log(e);
    }
    return 0;
};
/**
 * 
 * @returns {Number}
 */
P._get_image_height = function () {
    try {
        return this.get_background_image().height;
    } catch (e) {
        //console.log(e);
    }
    return 0;
};

/**
 * 
 * @returns {Number}
 */
P.get_scaled_image_width = function () {
    return  this.layout.scale * this.get_image_width();
};
/**
 * 
 * @returns {Number}
 */
P.get_scaled_image_height = function () {
    return  this.layout.scale * this.get_image_height();
};

/**
 * 
 * @returns {Number}
 */
P.get_pixel_image_offset_x = function () {
    return (this.layout.calculation_result.draw.x - this.layout.calculation_result.image.offset_x * this.layout.scale);
};
/**
 * 
 * @returns {Number}
 */
P.get_pixel_image_offset_y = function () {
    return (this.layout.calculation_result.draw.y - this.layout.calculation_result.image.offset_y * this.layout.scale);
};

/**
 * 
 * @returns {Number}
 */
P._get_layout_height = function () {
    return this.layout.layout_height;
};

/**
 * 
 * @returns {Number}
 */
P._get_layout_width = function () {
    return this.layout.layout_width;
};


/**
 * centralData object
 * @returns {Object}
 */
P.get_central_data = function () {
    return this.layout.centralData;
};

/**
 * current display view from centralData
 * @returns {Object}
 */
P.get_display_view = function () {
    return this.get_central_data().display_view;
};

/**
 * current image from centralData
 * @returns {HTMLImageElement}
 */
P.get_background_image = function () {
    return this.get_central_data().image_canvas;
};

/**
 * current scale from layerLayout
 * @returns {Number}
 */
P.get_scale = function () {
    return this.layout.scale;
};

P.norm_float = function (x) {
    return x.toFixed(10);
};

P.check_hilight_state = function () {

};

export default abstract_layer;