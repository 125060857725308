import { createRouter, createWebHistory } from 'vue-router'
import projectList from '@/components/projectList.vue';
import projectEdit from '@/components/projectEdit.vue';
import login from '@/components/login.vue';
import planBuilder from '@/components/planBuilder';
const routes = [
    {
        path: '/',
        name: 'home',
        component: projectList
    },
    {
        path: '/edit-project/:id',
        component: projectEdit,
        props: true
    },
    {
        path: '/edit-project',
        component: projectEdit
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: "/project/:id",
        component: planBuilder,
        props: true
    },
//    {
//        path: "/project/:pid/level/:lid",
//        component: planBuilder
//    },
    {
        path: '/*',
        component: projectList
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
