import U from './../../lib-utils';
import {central_data} from './../lib-central-data';
import tool from './../tool';
import axios from 'axios';
import events from './../lib-events';

var H = null;


function find_svg(n) {
    if (n.tagName.toLowerCase() === 'svg') {
        return n;
    }
    for (var i = 0; i < n.children.length; i++) {
        if (n.children[i].tagName) {
            var r = find_svg(n.children[i]);
            if (r) {
                return r;
            }
        }
    }
    return null;
}


function primitive_loader() {
    return (primitive_loader.is(this) ? this.init : primitive_loader.F).apply(this, Array.prototype.slice.call(arguments));
}
var L = U.fixup_constructor(primitive_loader).prototype;

L.id = null;
L.svg = null;

L.init = function (id) {
    this.id = id;
    var base_url = ['//', central_data().frontend_params.server, '/media/primitive/', this.id, '/primitive'].join('');
    axios.get([base_url, '.S!source.svg'].join(''))
            .then(this.on_svg_loaded.bindTo(this));
};

L.on_svg_loaded = function (re) {
    if (re) {
        if (re.data) {
            this.svg = U.NEString(re.data, null);
            if (this.svg) {
                H.on_load_complete(this);
            }
        }
    }
};


function primitive_cache() {
    return primitive_cache.is(H) ? H : ((primitive_cache.is(this) ? this.init : primitive_cache.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(primitive_cache).prototype;

P.index = null;


P.init = function () {
    H = this;
    this.index = {};
    return this;
};


P.has = function (x) {
    if (tool.is(x)) {
        return this.has(x.id);
    }
    var key = ['#', x].join('');
    return Object.prototype.hasOwnProperty.call(this.index, key) && !primitive_loader.is(this.index[key]);
};
P.load = function (x) {
    if (tool.is(x)) {
        return this.load(x.id);
    }
    var key = ['#', x].join('');
    if (!Object.prototype.hasOwnProperty.call(this.index, key)) {
        this.index[key] = primitive_loader(x);
    }
};

P.create_hidden_handle = function () {
    this.hidden_handle = document.createElement('div');
    this.hidden_handle.style.display = 'none';
    var bds = document.getElementsByTagName('body');
    if (bds.length) {
        bds[0].appendChild(this.hidden_handle);
    }
};

P.on_load_complete = function (loader) {
    var d = document.createElement('div');
    d.innerHTML = loader.svg;
    var svg = find_svg(d);
    if (svg) {
        var iid = ['svg-tool-cache-', loader.id].join('');
        svg.setAttribute('id', iid);
        if (!this.hidden_handle) {
            this.create_hidden_handle();
        }
        this.hidden_handle.appendChild(svg);
        this.index[['#', loader.id].join('')] = iid;
        central_data().lem.run(events._PRIMITIVE_LOADED, this);
    }
};

P.clone_defs_to = function(n){
    n.innerHTML = this.hidden_handle.innerHTML;
};


export default primitive_cache;