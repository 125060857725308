
import U from './lib-utils';
var H = null;
var passive_events_support = false;
var resize_observer_support = false;
(function () {
    try {
        var options = Object.defineProperty({}, "passive", {
            get: function () {
                passive_events_support = true;
                return 2;
            }
        });
        window.addEventListener("passive-events-test", null, options);
    } catch (err) {
        //nothing
    }

    if (window.ResizeObserver && typeof (window.ResizeObserver) === 'function') {
        resize_observer_support = true;
    }
})();

/*
 * Copyright Marc J. Schmidt. See the LICENSE file at the top-level
 * directory of this distribution and at
 * https://github.com/marcj/css-element-queries/blob/master/LICENSE.
 */
function ResizeSensor(element, callback) {
    var zIndex = parseInt(getComputedStyle(element));
    if (isNaN(zIndex)) {
        zIndex = 0;
    }
    zIndex--;
    this.element = element;
    this.callback = callback;

    this.expand = document.createElement('div');
    this.expand.style.position = "absolute";
    this.expand.style.left = "0px";
    this.expand.style.top = "0px";
    this.expand.style.right = "0px";
    this.expand.style.bottom = "0px";
    this.expand.style.overflow = "hidden";
    this.expand.style.zIndex = zIndex;
    this.expand.style.visibility = "hidden";

    this.expandChild = document.createElement('div');
    this.expandChild.style.position = "absolute";
    this.expandChild.style.left = "0px";
    this.expandChild.style.top = "0px";
    this.expandChild.style.width = "10000000px";
    this.expandChild.style.height = "10000000px";
    this.expand.appendChild(this.expandChild);

    this.shrink = document.createElement('div');
    this.shrink.style.position = "absolute";
    this.shrink.style.left = "0px";
    this.shrink.style.top = "0px";
    this.shrink.style.right = "0px";
    this.shrink.style.bottom = "0px";
    this.shrink.style.overflow = "hidden";
    this.shrink.style.zIndex = zIndex;
    this.shrink.style.visibility = "hidden";

    this.shrinkChild = document.createElement('div');
    this.shrinkChild.style.position = "absolute";
    this.shrinkChild.style.left = "0px";
    this.shrinkChild.style.top = "0px";
    this.shrinkChild.style.width = "200%";
    this.shrinkChild.style.height = "200%";
    this.shrink.appendChild(this.shrinkChild);
    element.appendChild(this.expand);
    element.appendChild(this.shrink);
    var self = this;
    function setScroll() {

        self.expand.scrollLeft = 10000000;
        self.expand.scrollTop = 10000000;

        self.shrink.scrollLeft = 10000000;
        self.shrink.scrollTop = 10000000;
    }
    setScroll();

    this.size = this.element.getBoundingClientRect();

    this.currentWidth = this.size.width;
    this.currentHeight = this.size.height;

    var onScroll = function () {
        var size = self.element.getBoundingClientRect();

        var newWidth = size.width;
        var newHeight = size.height;
        if (newWidth !== self.currentWidth || newHeight !== self.currentHeight) {
            self.currentWidth = newWidth;
            self.currentHeight = newHeight;
            self.callback(self.element);
        }
        setScroll();
    };
    if (passive_events_support) {
        this.expand.addEventListener('scroll', onScroll, {passive: true});
        this.shrink.addEventListener('scroll', onScroll, {passive: true});
    } else {
        this.expand.addEventListener('scroll', onScroll);
        this.shrink.addEventListener('scroll', onScroll);
    }

    this.remove = function () {
        if (passive_events_support) {
            this.expand.removeEventListener('scroll', onScroll, {passive: true});
            this.shrink.removeEventListener('scroll', onScroll, {passive: true});
        } else {
            this.expand.removeEventListener('scroll', onScroll);
            this.shrink.removeEventListener('scroll', onScroll);
        }
        this.expandChild.remove();
        this.shrinkChild.remove();
        this.expand.remove();
        this.shrink.remove();
        this.expandChild = null;
        this.shrinkChild = null;
        this.expand = null;
        this.shrink = null;
    };
}

var callbacks = {};
var observers = {};
var observer = null;

function get_node_observer_id(node) {
    if (node.dataset) {
        if (node.dataset.resizeMonitorObserverId) {
            return node.dataset.resizeMonitorObserverId;
        }
    }
    return null;
}
function uuid() {
    return ['rsm', 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).replace(/-/g, '')].join('');
}

function resizeMonitor() {
    if (H) {
        return H;
    } else if (this instanceof resizeMonitor) {
        return this.init();
    } else {
        return new resizeMonitor();
    }
}
var P = U.fixup_constructor(resizeMonitor).prototype;
P.init = function () {
    H = this;
    var self = this;
    this.binded_on_sensor = function () {
        self.on_observer.apply(self, Array.prototype.slice.call(arguments));
    };
    if (resize_observer_support) {        
        observer = new ResizeObserver(this.binded_on_sensor);
    }
};
P.on_observer = function (n) {//, i, g, h, t) {
    if (resize_observer_support) {
        for (var i = 0; i < n.length; i++) {
            var nodd = n[i].target;
            var ido = get_node_observer_id(nodd);
            if (ido) {
                if (typeof (callbacks[ido]) === 'function') {
                    callbacks[ido](nodd);
                }
            }
        }
    } else {
        var id = get_node_observer_id(n);
        if (id) {
            if (typeof (callbacks[id]) === 'function') {
                callbacks[id](n);
            }
        }
    }
};
P.on = function (node, callback) {
    var idl = get_node_observer_id(node);
    if (idl) {
        this.off(node);
    }
    if (typeof (callback) === 'function') {
        var id = uuid();
        if (!node.dataset) {
            node.dataset = {};
        }
        node.dataset.resizeMonitorObserverId = id;
        callbacks[id] = callback;
        if (resize_observer_support) {
            observer.observe(node);
        } else {
            var sen = new ResizeSensor(node, this.binded_on_sensor);
            observers[id] = sen;
        }
    }
};
P.off = function (node) {
    var id = get_node_observer_id(node);
    if (resize_observer_support) {
        observer.unobserve(node);
    } else {
        if (observers[id]) {
            observers[id].remove();
        }
        delete (observers[id]);
    }
    delete(node.dataset.resizeMonitorObserverId);
    delete(callbacks[id]);

};

export default resizeMonitor;

